export const SnippetConfig = {
  c: {
    clients: ['libcurl'],
    default: 'libcurl',
  },
  clojure: {
    clients: ['clj_http'],
    default: 'clj_http',
  },
  csharp: {
    clients: ['httpclient', 'restsharp'],
    default: 'restsharp',
  },
  go: {
    clients: ['native'],
    default: 'native',
  },
  http: {
    clients: ['http1.1'],
    default: 'http1.1',
  },
  java: {
    clients: ['asynchttp', 'nethttp', 'okhttp', 'unirest'],
    default: 'unirest',
  },
  javascript: {
    clients: ['axios', 'fetch', 'jquery', 'xhr'],
    default: 'fetch',
  },
  json: {
    clients: ['native'],
    default: 'native',
  },
  kotlin: {
    clients: ['okhttp'],
    default: 'okhttp',
  },
  node: {
    clients: ['axios', 'fetch', 'native', 'request', 'unirest'],
    default: 'fetch',
  },
  objc: {
    clients: ['nsurlsession'],
    default: 'nsurlsession',
  },
  ocaml: {
    clients: ['cohttp'],
    default: 'cohttp',
  },
  php: {
    clients: ['curl', 'guzzle', 'http1', 'http2'],
    default: 'curl',
  },
  powershell: {
    clients: ['restmethod', 'webrequest'],
    default: 'webrequest',
  },
  python: {
    clients: ['requests'],
    default: 'requests',
  },
  r: {
    clients: ['httr'],
    default: 'httr',
  },
  ruby: {
    clients: ['native'],
    default: 'native',
  },
  shell: {
    clients: ['curl', 'httpie', 'wget'],
    default: 'curl',
  },
  swift: {
    clients: ['urlsession'],
    default: 'urlsession',
  },
}

export type SnippetTargetType = keyof typeof SnippetConfig
