'use client'

import { useCallback, useState } from 'react'

import { ArrowsPointingOutIcon } from '@heroicons/react/24/solid'

import { CodeModal, type CodeModalProps } from '../CodeModal'

export const ExpandCodeButton: React.FC<CodeModalProps> = ({
  onCloseStart,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false)

  const onExpand = useCallback(() => setOpen(true), [])

  const onClose = useCallback(() => {
    setOpen(false)
    return onCloseStart?.()
  }, [onCloseStart, setOpen])

  return (
    <>
      <ArrowsPointingOutIcon
        className="h-5 w-5 fill-black-75 transition-colors stroke-black-500 hover:stroke-black-300 cursor-pointer"
        data-testid="expand-code-button"
        onClick={onExpand}
      />
      <CodeModal {...props} onCloseStart={onClose} open={open} />
    </>
  )
}
