import * as y from 'yup'

import type { GetInputSchema } from '../types'
import type { Field } from '@shared/openapi/types'

export const getAnyInputSchema = (
  getInputSchema: GetInputSchema,
  schema: Field.AnyField,
  required = false,
) => {
  let rule = y.mixed().test({
    message: {
      key: 'any',
    },
    test: (value) => {
      switch (typeof value) {
        case 'string':
          return getInputSchema(
            {
              ...schema,
              type: 'string',
              example: '',
              deprecated: false,
              description: '',
              required: required,
            },
            required,
          ).isValidSync(value)
        case 'number':
          return getInputSchema(
            {
              ...schema,
              type: 'number',
              example: 0,
              deprecated: false,
              description: '',
              required: required,
              exclusiveMax: false,
              exclusiveMin: false,
            },
            required,
          ).isValidSync(value)
        case 'boolean':
          return getInputSchema(
            {
              ...schema,
              type: 'boolean',
              example: false,
              deprecated: false,
              description: '',
              required: required,
            },
            required,
          ).isValidSync(value)
        case 'object':
          return getInputSchema(
            {
              ...schema,
              type: 'object',
              deprecated: false,
              description: '',
              required: required,
              properties: {},
            },
            required,
          ).isValidSync(value)
        default:
          return y.mixed().isValidSync(value)
      }
    },
  })
  if (required) rule = rule.required()
  return rule
}
