import type React from 'react'

import { StringEnumDual } from './StringEnumDual/StringEnumDual'
import { StringEnumMulti } from './StringEnumMulti/StringEnumMulti'
import { StringEnumSingle } from './StringEnumSingle/StringEnumSingle'

import type { FormInputFieldProps } from '../../types'
import type { Field } from '@shared/openapi/types'

export type StringEnumFieldProps = FormInputFieldProps<Field.StringField>

/**
 * A component that renders different selection interfaces for string enum fields
 * based on the number of enum values.
 */
export const StringEnumField: React.FC<StringEnumFieldProps> = (props) => {
  const { schema } = props
  const enumValues = schema.enum as string[]

  if (enumValues.length === 0) return null
  if (enumValues.length === 1) return <StringEnumSingle {...props} />
  if (enumValues.length === 2) return <StringEnumDual {...props} />
  return <StringEnumMulti {...props} />
}
