import { useCallback } from 'react'

import { Checkbox } from '@circlefin/components/lib/Checkbox'
import { useController } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

/**
 * Display the list of products for the feature request.
 */
export const FeatureRequestProducts: React.FC = () => {
  const { field } = useController<{ products: string[] }, 'products'>({
    name: 'products',
    defaultValue: [],
  })

  const { t } = useTranslation('common')

  const products = [
    t`modals.submitFeatureRequest.products.wallets`,
    t`modals.submitFeatureRequest.products.scp`,
    t`modals.submitFeatureRequest.products.gas`,
    t`modals.submitFeatureRequest.products.cctp`,
    t`modals.submitFeatureRequest.products.usdc`,
    t`modals.submitFeatureRequest.products.eurc`,
  ]

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target

      const newValue = checked
        ? [...field.value, name]
        : field.value.filter((item) => item !== name)

      field.onChange(newValue)
    },
    [field],
  )

  return (
    <Checkbox.Group
      className="flex w-full"
      data-testid="featureRequestProducts"
      direction="vertical"
      label=" "
      name="products"
    >
      {products.map((item) => (
        <Checkbox
          key={item}
          checked={field.value.includes(item)}
          label={item}
          name={item}
          onChange={handleChange}
        />
      ))}
    </Checkbox.Group>
  )
}
