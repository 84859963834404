import type { FC } from 'react'

import { CWIcon } from '@features/common.components/ComponentsWeb/Icon'
import useTranslation from 'next-translate/useTranslation'

export interface NoResultsProps {
  searchKey?: string | null
}

export const NoResults: FC<NoResultsProps> = ({ searchKey }) => {
  const { t } = useTranslation('algolia')

  return (
    <div className="h-64 w-full flex items-center justify-start flex-col space-y-4">
      <div className="text-5xl mt-4">
        <CWIcon.Circle intent="warning" name="XSolid" />
      </div>
      <div className="flex flex-col text-center pt-4">
        <div className="type-h-title-md">{t`modals.search.noResults`}</div>
        <div className="type-body-base">&quot;{searchKey}&quot;</div>
      </div>
    </div>
  )
}
