import * as y from 'yup'

import type { GetInputSchema } from '../types'
import type { Field } from '@shared/openapi/types'

export const getObjectSchema = (
  getInputSchema: GetInputSchema,
  schema: Field.ObjectField,
  required = false,
) => {
  let rule = y.object().default(null)
  if (required) {
    rule = rule.nullable().required()
  }
  rule = rule.shape(
    Object.keys(schema.properties).reduce((result, name) => {
      return {
        ...result,
        [name]: getInputSchema(schema.properties[name]),
      }
    }, {}),
  )
  return rule as unknown as y.Schema
}
