import { ModalRoute } from '@circlefin/modal-router'
import { routes } from '@features/common.constants'

import { FeatureRequestForm } from './FeatureRequestForm/FeatureRequestForm'
import { Feedback } from './Feedback/Feedback'
import { Success } from './Success/Success'

export { commonModalRegistry } from './registry'

export { routes }

/**
 * Common Modal Router.
 */
export const CommonRouter = () => {
  return (
    <>
      <ModalRoute content={<Feedback />} path={routes.feedback} />
      <ModalRoute
        content={<FeatureRequestForm />}
        path={routes.featureRequest}
      />
      <ModalRoute content={<Success />} path={routes.success} />
    </>
  )
}
