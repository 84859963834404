import { useDatadogRum } from '@circlefin/datadog'

import { useFeatureFlags } from '../useFeatureFlags'

import type { FeatureFlags } from '@features/flags.graphql'

/**
 * Custom hook to access a specific feature flag.
 *
 * This hook retrieves the value of a specific feature flag from the FeatureFlagsContext.
 * If Datadog analytics are enabled, it also logs the feature flag evaluation to Datadog RUM.
 * @param key - The key of the feature flag to retrieve.
 * @throws If the feature flag key does not exist in the flags object.
 * @throws If used outside of a FeatureFlagsProvider (from useFeatureFlags).
 * @returns The value of the requested feature flag.
 */
export const useFeatureFlag = <
  TKey extends Exclude<keyof FeatureFlags, '__typename'>,
>(
  key: TKey,
): FeatureFlags[TKey] => {
  const flags = useFeatureFlags()
  const { addFeatureFlagEvaluation } = useDatadogRum()

  if (!(key in flags)) {
    throw new Error(`Feature flag "${key}" does not exist`)
  }

  const value = flags[key]
  if (flags.analyticsEnabled.datadog) {
    addFeatureFlagEvaluation(key, value)
  }

  return value
}
