import type React from 'react'

import { createFormDropdown } from '@circlefin/form/Form.Dropdown'

import { getInputLabel } from '../../../getInputLabel/getInputLabel'

import type { FormInputFieldProps } from '../../../types'
import type { Field } from '@shared/openapi/types'

export type StringEnumMultiProps = FormInputFieldProps<Field.StringField>

const Dropdown = createFormDropdown()

/**
 * Allows selection from multiple enum values for a string field using a dropdown.
 */
export const StringEnumMulti: React.FC<StringEnumMultiProps> = ({
  name,
  schema,
  prefix,
}) => {
  const fieldName = [prefix, name].filter(Boolean).join('.')
  const label = getInputLabel({ schema, name })
  const options = [
    { label: '', value: undefined },
    ...(schema.enum as string[]).map((value) => ({
      label: value,
      value,
    })),
  ]

  return (
    <div className="w-full">
      <label className="text-neutral inline-block type-body-sm-bold mb-1">
        {label}
      </label>
      <Dropdown
        data-testid="string-enum-multi"
        items={options}
        name={fieldName}
        placeholder={schema.example}
      />
    </div>
  )
}
