import { useCallback } from 'react'

import { uuid } from '@shared/utils'
import useTranslation from 'next-translate/useTranslation'

import { ActionInputField } from '../ActionInputField/ActionInputField'
import { StringInputField } from '../StringInputField/StringInputField'

import type { InputFieldAction } from '../ActionInputField/ActionInputField'
import type { StringInputFieldProps } from '../StringInputField/StringInputField'

/**
 * Props for the IdempotencyKeyField component.
 * It extends StringInputFieldProps to include all props from StringInputField.
 */
export type IdempotencyKeyFieldProps = StringInputFieldProps

/**
 * A component that renders an input field for an idempotency key with a "Generate" button.
 * The "Generate" button creates a new UUID when clicked.
 */
export const IdempotencyKeyField: React.FC<IdempotencyKeyFieldProps> = (
  props,
) => {
  const { t } = useTranslation('playground')
  /**
   * Callback function to generate a new UUID and set it as the field value.
   */
  const handleActionInvocation = useCallback<InputFieldAction>(
    ({ setValue }) => {
      setValue(uuid())
    },
    [],
  )

  return (
    <div className="w-full relative" data-testid="inputfield-idempotency-key">
      <ActionInputField
        action={handleActionInvocation}
        actionName={t`form.generate`}
        name={props.name}
        prefix={props.prefix}
      >
        <StringInputField {...props} />
      </ActionInputField>
    </div>
  )
}
