import type { NavigationItemElement } from '@features/navigation.components/Navigation/types'

export const calculateOffset = (contentWrapperId?: string): number => {
  const contentWrapper = contentWrapperId
    ? document.getElementById(contentWrapperId)
    : null

  /**
   * Calculate the offset if the content wrapper applies a top padding.
   */
  return contentWrapper !== null
    ? Number(
        window
          .getComputedStyle(contentWrapper)
          .getPropertyValue('padding-top')
          .replace('px', ''),
      )
    : 0
}

/**
 * Calculate vertical position of the element in the DOM. Take offset into account.
 */
export const getVerticalPositionFromElement = (
  element: Element,
  offset: number = 0,
) => {
  return element.getBoundingClientRect().top + window.scrollY - offset
}

/**
 * Derive sections from HTML DOM.
 */
export const calculateSections = (offset: number = 0) => {
  return Array.from(document.querySelectorAll('h2, h3')).reduce(
    (result: NavigationItemElement[], heading) => {
      // Ignoring Cookies Preference Center when calculate Sections when scrolling
      if (heading.textContent === 'Cookies Preference Center') return result
      if (heading.tagName === 'H2') {
        result.push({
          url: '#' + heading.id,
          label: heading.textContent ?? '',
          position: getVerticalPositionFromElement(heading, offset),
          children: [],
        })
      } else if (heading.tagName === 'H3') {
        result[result.length - 1]?.children?.push({
          url: '#' + heading.id,
          label: heading.textContent ?? '',
          position: getVerticalPositionFromElement(heading, offset),
        })
      }
      return result
    },
    [],
  )
}
