import { useCallback, useEffect } from 'react'

import { Input } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@features/algolia.constants'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

interface SearchInputFieldProps {
  /**
   * Should the search input take up the full width of its parent.
   */
  fullWidth?: boolean
}

export const SearchInputField: React.FC<SearchInputFieldProps> = ({
  fullWidth = false,
}) => {
  const { t } = useTranslation('algolia')
  const { router } = useModal()

  const handleClick = useCallback(() => {
    void router.push(routes.search)
  }, [router])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.altKey && e.code === 'KeyS') {
        void router.push(routes.search)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [router])

  return (
    <button
      className={classNames(
        'cursor-pointer',
        {
          'lg:w-76 md:w-48 transition-[width] duration-500 ease-in-out':
            !fullWidth,
        },
        {
          'w-full': fullWidth,
        },
      )}
      data-testid="search-input"
      onClick={handleClick}
    >
      <Input className="w-full">
        <Input.Field.Custom>
          <Input.Inline.Icon
            align="left"
            className="ml-2"
            name="SearchOutline"
          />
          <span className="text-neutral-subtlest">
            {t('components.search.placeholder')}
          </span>
          <Input.Inline.Affix className="mr-2" variant="suffix">
            ⌥ + S
          </Input.Inline.Affix>
        </Input.Field.Custom>
      </Input>
    </button>
  )
}
