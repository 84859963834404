/**
 * A function for flattening the `oneOf-...` properties from an object, usually the request body.
 * We need to do this because of the way the `oneOf` fields are processed in the form schema (see `getOneOfInputSchema.ts`).
 *
 * Example.
 * ```json
 * {
 *   'oneOf-0': {
 *     cryptoAmount: {
 *       currency: 'USDC',
 *     },
 *   },
 * },
 * ```
 *
 * Becomes.
 * ```json
 * {
 *   cryptoAmount: {
 *     currency: 'USDC',
 *   },
 * }.
 * ```
 *
 * See the tests for this function in `helpers.test.ts` for more detailed examples.
 * @param data - An form values object, usually the request body.
 * @returns The data transformed by flattening any `oneOf-...` attributes.
 */
export const removeOneOfs = (
  data: Record<string, unknown> | unknown[] | undefined,
): Record<string, unknown> | unknown[] | undefined => {
  if (!data) {
    return data
  }

  if (Array.isArray(data)) {
    // If the data is an array, we need to preprocess it by flattening any single `oneOf` objects
    // This is the case for something like an `array of oneOfs`
    // See the second test in `Provider.test.tsx` for an array exmaple
    const cleanedArrayData = data.map((item) => {
      if (
        typeof item === 'object' &&
        item !== null &&
        Object.keys(item).length === 1 &&
        Object.keys(item)[0].includes('oneOf-')
      ) {
        // if the item is a single `oneOf` object, return the value of the object
        return (item as Record<string, unknown>)[Object.keys(item)[0]]
      }

      return item
    })

    // recursively call `removeOneOfs` on the array
    return cleanedArrayData.map((item) =>
      typeof item === 'object' && item !== null
        ? removeOneOfs(item as Record<string, unknown>)
        : item,
    )
  }

  const result: Record<string, unknown> = {}

  for (const [key, value] of Object.entries(data)) {
    // if the key is a `oneOf` key, and the value is an object, flatten the object
    if (key.includes('oneOf-') && typeof value === 'object' && value != null) {
      Object.assign(result, removeOneOfs(value as Record<string, unknown>))
    } else if (typeof value === 'object' && value != null) {
      // if the value is an object, recursively call `removeOneOfs` on the object
      result[key] = removeOneOfs(value as Record<string, unknown>)
    } else {
      // otherwise, just assign the value to the result
      result[key] = value
    }
  }

  return result
}
