'use client'

import classNames from 'classnames'

import { ApiReferenceSharedNavigation } from '../ApiReferenceSharedNavigation/ApiReferenceSharedNavigation'

import type { ApiReferenceChildProps } from '../ApiReferenceNavigation'

/**
 * ApiReferenceNavigation component for desktop.
 */
export const ApiReferenceDesktopNavigation = (
  props: ApiReferenceChildProps,
) => {
  return (
    <div
      className={classNames(
        'hidden md:block w-64 px-4 pt-6 lg:w-80 flex-grow-0 h-full border-r border-neutral-subtle overflow-y-scroll',
      )}
      data-testid="api-reference-desktop-navigation"
    >
      <ApiReferenceSharedNavigation {...props} />
    </div>
  )
}
