import { useCallback, useMemo, useState } from 'react'

import { MultiSelectCombobox } from '@circlefin/components'
import { useController } from '@circlefin/form'
import { isStringField } from '@shared/openapi/guards'
import { matchSorter } from 'match-sorter'
import useTranslation from 'next-translate/useTranslation'

import { getInputLabel } from '../../getInputLabel/getInputLabel'

import type { FormInputFieldProps } from '../../types'
import type { Field } from '@shared/openapi/types'

/**
 * Props for the MultiSelectEnumField component.
 * Extends FormInputFieldProps with a Field.ArrayField schema.
 */
export type MultiSelectEnumFieldProps = FormInputFieldProps<Field.ArrayField>

/**
 * A form input field for selecting multiple enum values from a string array.
 * @param props - The component props.
 * @returns The rendered MultiSelectEnumField component.
 */
export const MultiSelectEnumField: React.FC<MultiSelectEnumFieldProps> = ({
  name,
  schema,
  prefix,
}) => {
  const { t } = useTranslation('common')
  const { field } = useController({
    name: prefix ? `${prefix}.${name}` : name,
    defaultValue: [],
  })

  const [searchTerm, setSearchTerm] = useState('')

  const value = field.value as string[]

  const items = useMemo(() => {
    if (!isStringField(schema.items)) return []
    return (schema.items.enum ?? []).map((val) => ({ label: val, value: val }))
  }, [schema.items])

  const filtered = useMemo(() => {
    return matchSorter(items, searchTerm, {
      keys: ['label'],
    })
  }, [items, searchTerm])

  const label = useMemo(() => getInputLabel({ schema, name }), [schema, name])

  const handleOnChange = useCallback(
    (values: Array<string | undefined>) => {
      field.onChange(values)
      field.onBlur()
    },
    [field],
  )

  const handleClearInput = useCallback(() => {
    setSearchTerm('')
  }, [])

  const handleInputChange = useCallback((searchTerm: string) => {
    setSearchTerm(searchTerm)
  }, [])

  return (
    <div className="w-full" data-testid="multiselect-enum-field">
      <MultiSelectCombobox
        filtered={filtered}
        items={items}
        label={label}
        name={prefix ? `${prefix}.${name}` : name}
        onBlur={field.onBlur}
        onChange={handleOnChange}
        onClearInput={handleClearInput}
        onInputChange={handleInputChange}
        searchTerm={searchTerm}
        selectedValues={value}
        visibleSelectedCount={5}
      >
        <MultiSelectCombobox.Options
          items={filtered}
          locale={{
            clearButtonLabel: t`forms.multiSelect.clearButtonLabel`,
            noResultsMessage: t`forms.multiSelect.noResultsMessage`,
            noItemsMessage: t`forms.multiSelect.noItemsMessage`,
          }}
        />
      </MultiSelectCombobox>
    </div>
  )
}
