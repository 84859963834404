import { SplitPane } from '@features/playground.components'

import { ParameterForm } from './ParameterForm/ParameterForm'
import { PlaygroundProvider } from './Provider/Provider'
import { RequestTemplate } from './RequestTemplate/RequestTemplate'
import { Response } from './Response/Response'

import type { Operation } from '@features/playground.graphql'
/**
 * Props for the Playground component.
 */
export interface PlaygroundProps {
  /**
   * The API operation to be displayed and interacted with in the playground.
   */
  operation: Operation
}

/**
 * Playground component for interacting with API operations.
 *
 * This component provides a split-pane interface with a parameter form on the left
 * and request template and response sections on the right.
 * @param props - The props for the Playground component.
 * @returns The rendered Playground component.
 */
export const Playground: React.FC<PlaygroundProps> = ({ operation }) => {
  return (
    <PlaygroundProvider operation={operation}>
      <div
        className="h-full w-full flex-1 flex px-2"
        data-testid="playground-core"
      >
        <SplitPane className="flex-1" minSize={450} sizes={[50, 50]}>
          <ParameterForm key={`form.${operation.name}`} />
          <div className="w-full flex items-stretch justify-start flex-col space-y-6 py-6 pl-4 pr-6">
            <RequestTemplate key={`template.${operation.name}`} />
            <Response key={`response.${operation.name}`} />
          </div>
        </SplitPane>
      </div>
    </PlaygroundProvider>
  )
}
