'use client'

import type { ChangeEventHandler } from 'react'
import { useCallback, useEffect } from 'react'

import { useController } from '@circlefin/form'
import { createFormInput } from '@circlefin/form/Form.Input'
import { GLOBAL_PLAYGROUND_PROJECT_SETTINGS_CHANGED } from '@features/playground.constants/events'
import { useGlobalPlaygroundContext } from '@features/playground.hooks/useGlobalPlaygroundContext'
import { usePlaygroundState } from '@features/playground.hooks/usePlaygroundState'
import classNames from 'classnames'
import get from 'lodash.get'
import useTranslation from 'next-translate/useTranslation'

import { InputType } from '../InputType/InputType'
import { getInputLabel } from '../getInputLabel/getInputLabel'

import type { GlobalProjectSettingsChangedPayload } from '@features/playground.constants/events'
import type { StringField } from '@shared/openapi/transform/field/types'
import type { Translate } from 'next-translate'

const Input = createFormInput()

/**
 * A unique key to identify this component as the initiator of changes.
 */
export const INITIATOR_KEY = 'ApiKeyInputField'

/**
 * Generates the input schema for the API Key input field.
 * @param t - A translation function for accessing translated strings.
 * @returns The schema for the API Key input field.
 */
const getSchema = (t: Translate): StringField => ({
  description: '',
  deprecated: false,
  type: 'string',
  title: t`apiKey.label`,
  required: true,
  example: '',
})

/**
 * ApiKeyInputField Component.
 *
 * This component renders an input field to accept an API key. It manages the state
 * of the API key, synchronizes it with global project settings, and handles changes.
 * @returns The rendered component.
 */
export const ApiKeyInputField: React.FC = () => {
  const { t } = useTranslation('playground')
  const { operation } = usePlaygroundState()
  const [{ project }, { emitter, handleProjectSettingsChange }] =
    useGlobalPlaygroundContext()

  const schema = getSchema(t)

  const {
    field: { onChange: onFieldChange },
  } = useController({
    name: 'bearer',
  })

  // Set initial API key from project settings
  useEffect(() => {
    const apiKey = get(project.current, [operation.group, 'apiKey'], '')

    if (apiKey !== '') {
      onFieldChange(apiKey)
    }
  }, [onFieldChange, operation.group, project])

  /**
   * Handles changes in global project settings.
   * @param data - The payload containing the changed settings.
   */
  const handleGlobalProjectSettingsChanged = useCallback(
    (data: GlobalProjectSettingsChangedPayload) => {
      if (data.initiator !== INITIATOR_KEY) {
        const apiKey = get(data.settings, [operation.group, 'apiKey'], '')
        onFieldChange(apiKey)
      }
    },
    [onFieldChange, operation.group],
  )

  // Set up and clean up event listener for global project settings changes
  useEffect(() => {
    emitter.on(
      GLOBAL_PLAYGROUND_PROJECT_SETTINGS_CHANGED,
      handleGlobalProjectSettingsChanged,
    )

    return () =>
      emitter.off(
        GLOBAL_PLAYGROUND_PROJECT_SETTINGS_CHANGED,
        handleGlobalProjectSettingsChanged,
      )
  }, [emitter, handleGlobalProjectSettingsChanged])

  /**
   * Handles changes to the API key input field.
   * @param event - The change event from the input field.
   */
  const handleApiKeyChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      handleProjectSettingsChange(
        operation.group,
        {
          apiKey: event.currentTarget.value,
        },
        INITIATOR_KEY,
      )
    },
    [handleProjectSettingsChange, operation.group],
  )

  return (
    <div
      className={classNames(
        'w-full px-4 py-3 flex items-center bg-surface-background border-neutral-subtle border rounded-sm gap-2 flex-col',
      )}
      data-testid="inputfield-apikey"
    >
      <span className="text-sm font-circular-regular text-black w-full">
        {t(`apiKey.description`)}
      </span>
      <div className="w-full relative">
        <Input
          className="flex-1 w-full"
          label={getInputLabel({ schema })}
          name="bearer"
          onChange={handleApiKeyChange}
        />
        <InputType schema={schema} />
      </div>
    </div>
  )
}
