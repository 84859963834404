import * as y from 'yup'

/**
 * Regular expression for validating entity secrets.
 * Matches a string of 64 lowercase alphanumeric characters.
 */
const ENTITY_SECRET_REGEX = /^[a-z0-9]{64}$/

/**
 * Yup schema for validating entity secrets.
 * @remarks
 * The entity secret must be a string of exactly 64 lowercase alphanumeric characters.
 * @returns A Yup string schema with specific validation rules for entity secrets.
 */
export const getEntitySecretSchema = () =>
  y.string().required().matches(ENTITY_SECRET_REGEX, {
    name: 'entitySecret',
  })
