import { routes } from '@features/playground.constants'
import { screenConfig } from '@shared/modals'

import type { ModalRegistry } from '@circlefin/modal-router'

/**
 * Playground modal routes registry.
 */
export const playgroundModalRegistry: ModalRegistry = {
  [routes.interactivePlayground]: screenConfig({
    disableBack: true,
    disableClose: false,
    size: 'screen',
  }),
}
