import { isFieldType } from '../isFieldType/isFieldType'

import type { Field } from '../../../types'

/**
 * Checks if the given value is a valid `BooleanField` object.
 * @param value - The value to be checked.
 * @returns - `true` if the value is a valid `BooleanField` object, `false` otherwise.
 */
export const isBooleanField = (
  value?: unknown,
): value is Field.BooleanField => {
  return isFieldType(value) && value.type === 'boolean'
}
