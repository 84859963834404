import * as y from 'yup'

import type { Field } from '@shared/openapi/types'

export const getIntegerInputSchema = (
  schema: Field.IntegerField,
  required = false,
) => {
  let rule = y
    .number()
    .integer()
    .transform((value: number) => (Number.isNaN(value) ? undefined : value))
  if (required) rule = rule.required()
  if (schema.max !== undefined) {
    rule = schema.exclusiveMax
      ? rule.lessThan(schema.max, {
          key: 'maxValue',
          values: { max: schema.max },
        })
      : rule.max(schema.max, { key: 'maxValue', values: { max: schema.max } })
  }
  if (schema.min !== undefined) {
    rule = schema.exclusiveMin
      ? rule.moreThan(schema.min, {
          key: 'minValue',
          values: { min: schema.min },
        })
      : rule.min(schema.min, { key: 'minValue', values: { min: schema.min } })
  }
  return rule
}
