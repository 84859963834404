import * as y from 'yup'

/**
 * Generates a schema for API keys.
 *
 * The schema expects a string that matches a specific pattern:
 * must be of the form "TEST_API_KEY:...", "LIVE_API_KEY:...", "SMOK_API_KEY:...","SAND_API_KEY:...",.
 *
 * The key should be at least 60 characters long. This is because:
 * TEST_API_KEY - 12 chars
 * Key ID length - 32 chars
 * Key Secret - 32 chars
 * :'s - 3 chars
 * So minimum length of the key should be 79.
 *
 * Some old API Keys didn't have a prefix so we decided 60 would be a ideal length.
 *
 * And a Base64 encoded string will always be larger than the original string it is derived from.
 * @returns The validation schema for API keys.
 */
export const getApiKeySchema = () => {
  return y
    .string()
    .required()
    .min(60, { key: 'apiKey' })
    .matches(
      /(?:TEST|LIVE|SMOK|SAND)_API_KEY:[a-z0-9]*:[a-z0-9]*|^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/gm,
      {
        name: 'apiKey',
      },
    )
}
