'use client'

import { useCallback, useMemo, useState } from 'react'

import { Icon } from '@circlefin/components'
import classNames from 'classnames'

export interface CopyToClipboardProps {
  /**
   * Stringified code.
   */
  code: object | string
}
export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ code }) => {
  const [isCopied, setCopied] = useState(false)

  const isCodeAvailable = useMemo(() => {
    return Boolean(code)
  }, [code])

  const copyToClipboard = useCallback(() => {
    if (isCodeAvailable) {
      void navigator.clipboard.writeText(
        typeof code === 'object' ? JSON.stringify(code, null, 2) : code,
      )
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  }, [code, isCodeAvailable])

  if (isCopied) {
    return (
      <Icon
        className="h-5 w-5 transition-colors text-icon-success"
        name="CheckCircleSolid"
        onClick={copyToClipboard}
      />
    )
  }

  return (
    <Icon
      className={classNames('h-5 w-5 fill-black-75 transition-colors  ', {
        'stroke-black-500 hover:stroke-black-300 cursor-pointer':
          isCodeAvailable,
        'stroke-black-200': !isCodeAvailable,
      })}
      name="ClipboardListOutline"
      onClick={copyToClipboard}
    />
  )
}
