/**
 * Parameters for the site level banner.
 */
export type BannerProps = {
  title?: string
  description?: string
  link?: {
    title: string
    path: string
  }
}
/**
 * A list of banners to show on the page. Multiple banners will be rendered in order defined.
 * To remove banners from the page, make banners an empty array i.e. export const banners: BannerProps[] = [].
 * @example
 * ```ts
 * export const banners: BannerProps[] = [
 *   {
 *     title: 'We refreshed our doc site!',
 *     description: 'Bookmarked links may have changed',
 *     link: {
 *       title: 'Read release notes',
 *       path: '/release-notes/w3s-2024',
 *     },
 *   },
 * ]
 * ```
 */
export const banners: BannerProps[] = [
  {
    title: 'Travel rule support is now available for Compliance Engine!',
    description: 'Try it out on the',
    link: {
      title: 'Developer Console',
      path: 'http://console.circle.com',
    },
  },
]

// assuming every banner has one line of text and loaded on desktop
export const notificationHeight = banners.length * 44
