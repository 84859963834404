import { useEffect, useState, useCallback } from 'react'

import { GLOBAL_PLAYGROUND_SETTINGS_CHANGED } from '@features/playground.constants/events'

import { useGlobalPlaygroundContext } from './../useGlobalPlaygroundContext'

import type { GlobalSettings } from '@features/playground.constants/events'

/**
 * A custom hook that provides access to the current global settings of the Global Playground
 * and a method to update these settings.
 * It syncs with the GlobalPlaygroundContext and listens for global settings change events.
 * @returns An object containing the current global settings and a setter function.
 */
export const useGlobalPlaygroundSettings = (): [
  GlobalSettings,
  (newSettings: Partial<GlobalSettings>) => void,
] => {
  const [{ global }, { emitter, handleGlobalSettingsChange }] =
    useGlobalPlaygroundContext()
  const [settings, setSettings] = useState<GlobalSettings>(global.current)

  useEffect(() => {
    emitter.on(GLOBAL_PLAYGROUND_SETTINGS_CHANGED, setSettings)

    /**
     * Cleanup function to unsubscribe from events.
     */
    return () => {
      emitter.off(GLOBAL_PLAYGROUND_SETTINGS_CHANGED, setSettings)
    }
  }, [emitter])

  const updateSettings = useCallback(
    (newSettings: Partial<GlobalSettings>) => {
      handleGlobalSettingsChange(newSettings)
    },
    [handleGlobalSettingsChange],
  )

  return [settings, updateSettings]
}
