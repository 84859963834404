import { createFormInput } from '@circlefin/form/Form.Input'

import { InputType } from '../InputType/InputType'
import { getInputLabel } from '../getInputLabel/getInputLabel'

import { StringEnumField } from './StringEnumField/StringEnumField'

import type { FormInputFieldProps } from '../types'
import type { Field } from '@shared/openapi/types'

export type StringInputFieldProps = FormInputFieldProps<Field.StringField>

const Input = createFormInput()

export const StringInputField: React.FC<StringInputFieldProps> = ({
  schema,
  name,
  prefix,
}) => {
  return (
    <div className="w-full relative">
      {schema.enum ? (
        <StringEnumField name={name} prefix={prefix} schema={schema} />
      ) : (
        <Input
          data-testid="inputfield-string"
          label={getInputLabel({
            schema,
            name,
          })}
          name={prefix ? [prefix, name].join('.') : name}
          placeholder={schema.example}
        />
      )}
      <InputType schema={schema} />
    </div>
  )
}
