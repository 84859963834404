import { useCallback } from 'react'

import { Icon } from '@circlefin/components/lib/Icon'
import { Modal } from '@circlefin/components/lib/Modal'
import { TextLink } from '@circlefin/components/lib/TextLink'
import { useModal } from '@circlefin/modal-router/useModal/useModal'
import { routes } from '@features/common.constants'
import useTranslation from 'next-translate/useTranslation'
import * as y from 'yup'

const _schema = y.object({
  subject: y.string().required(),
  description: y.string(),
})

export type SupportTicketFormValue = y.InferType<typeof _schema>

export const Success: React.FC = () => {
  const { t } = useTranslation('common')

  const modal = useModal()

  const inputEmail = (modal.history.query.email ?? '') as string

  const openFeatureRequestForm = useCallback(() => {
    modal.router.push(routes.featureRequest)
  }, [modal])

  return (
    <>
      <Modal.Title />
      <Modal.Body className="mt-8 flex flex-col items-center justify-center type-body-base">
        <div className="text-center">
          <div className="flex items-center justify-center">
            <Icon.Circle intent="success" name="CheckCircleSolid" />
          </div>
          <h3 className="mt-4 mb-2 text-2xl text-neutral-strong font-circular-medium">{t`modals.success.title`}</h3>

          <p className="mb-8 type-body-sm">
            {t`modals.success.response`}{' '}
            <strong className="text-neutral">{inputEmail}</strong>
          </p>

          <div className="grid grid-cols-2 gap-6 pt-4">
            <div>
              <div className="mb-3 type-body-sm-bold">{t`modals.success.discord.title`}</div>
              <TextLink.Link
                className="type-body-base-bold"
                href={t`modals.success.discord.link`}
                variant="primary"
              >
                <Icon name="Social/Discord" />
                {t`modals.success.discord.action`}
              </TextLink.Link>
            </div>
            <div>
              <div className="mb-3 type-body-sm-bold">{t`modals.success.featureRequest.title`}</div>
              <TextLink.Link
                className="type-body-base-bold"
                onClick={openFeatureRequestForm}
                variant="primary"
              >
                {t`modals.success.featureRequest.action`}
              </TextLink.Link>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  )
}
