import requestIp from 'request-ip'

import type { Request } from 'request-ip'

/**
 * Parse IP address from given http request
 * return 127.0.0.1 for localhost.
 */
export const getIp = (req: Request): string | null => {
  // https://github.com/pbojinov/request-ip/issues/48
  const cloudFlareConnectingIp = req.headers?.['cf-connecting-ip'] as
    | string
    | undefined

  // prioritize cloudflare connecting ip if exists
  const userIp = cloudFlareConnectingIp ?? requestIp.getClientIp(req)

  if (!userIp) {
    return null
  }

  if (userIp === '::1') {
    return '127.0.0.1'
  }

  return userIp
}
