import { routes } from '@features/algolia.constants'
import { screenConfig } from '@shared/modals'

import type { ModalRegistry } from '@circlefin/modal-router'

export const algoliaModalRegistry: ModalRegistry = {
  [routes.search]: screenConfig({
    disableBack: true,
    disableClose: false,
    size: 'md',
  }),
}
