'use client'

import { useEffect } from 'react'
import type { FC } from 'react'

import { Sidenav } from '@circlefin/components'
import { Button } from '@circlefin/components/lib/Button'
import { Icon } from '@circlefin/components/lib/Icon'
import { ModalLink } from '@circlefin/modal-router'
import { SearchInputField } from '@features/algolia.components/src/SearchInputField'
import { Link } from '@features/common.components/Link'
import { useFeatureFlag } from '@features/flags.hooks/useFeatureFlag'
import { useNavigation } from '@features/navigation.hooks/useNavigation'
import { routes } from '@features/playground.constants'
import classNames from 'classnames'
import NextLink from 'next/link'
import { usePathname } from 'next/navigation'
import useTranslation from 'next-translate/useTranslation'

import { NavGroup } from '../NavGroup/NavGroup'
import { NavIconItem } from '../NavIconItem/NavIconItem'
import { NavItem } from '../NavItem/NavItem'
import { NavSection } from '../NavSection/NavSection'

import type { NavigationPropsWithContentWrapper } from '../desktop/DesktopNavigation'

export const MobileNavigation: FC<NavigationPropsWithContentWrapper> = ({
  title,
  parent,
  items,
}) => {
  const { t } = useTranslation('common')
  const [showNavigation, setShowNavigation] = useNavigation()
  const pathname = usePathname()
  const migrationEnabled = useFeatureFlag('migrationEnabled')

  useEffect(() => {
    setShowNavigation(false)
  }, [pathname, setShowNavigation])

  if (!showNavigation) return null
  return (
    <div className="z-30 fixed lg:static w-full lg:hidden top-header bottom-0 left-0">
      <Sidenav
        aria-label="example"
        className="gap-0 overflow-y-auto w-full bg-white p-6"
        variant="light"
      >
        {!parent && (
          <div className="mb-6" data-testid="search-input-container">
            <SearchInputField fullWidth />
          </div>
        )}

        {parent ? (
          <NextLink href={parent.url}>
            <Button label={parent.label} size="base" variant="text">
              <Icon className="mr-2" name="ArrowNarrowLeftSolid" size={24} />
              {parent.label}
            </Button>
          </NextLink>
        ) : null}

        {title ? <p className="type-h-title-md pt-0.5 ml-2">{title}</p> : null}

        <div className="flex flex-col gap-2">
          {items.map((item, index) => {
            if ('items' in item || 'children' in item) {
              if ('items' in item) {
                return <NavSection key={index} section={item} />
              }
              if ('children' in item) {
                return <NavGroup key={index} index={index} item={item} />
              }
            } else {
              return item.icon ? (
                <NavIconItem
                  key={`${item.url}-${index}`}
                  className="p-0.5 hover:border-blue-300"
                  href={item.url.toLowerCase()} // temporary solution until we standardize the naming of 'quickstarts'
                  icon={item.icon}
                  label={item.label}
                />
              ) : (
                <NavItem
                  key={`${item.url}-${index}`}
                  active={item.url === pathname}
                  className={classNames(
                    'pl-2 p-0.5 transition-gradient duration-300',
                    { 'mt-4': index === 0 },
                    {
                      'border-blue-300 font-circular-bold':
                        item.url === pathname,
                    },
                    {
                      'hover:bg-primary-subtle-hovered': item.url !== pathname,
                    },
                  )}
                  href={item.fallbackUrl ?? item.url}
                  label={item.label}
                />
              )
            }
          })}
        </div>

        {!parent && (
          <div className="mt-6 flex flex-col gap-y-2">
            {migrationEnabled && (
              <ModalLink pathname={routes.interactivePlayground}>
                <Button
                  className="normal-case w-fit"
                  label={t('header.links.apiPlayground')}
                  size="base"
                  variant="text"
                >
                  {t('header.links.apiPlayground')}
                </Button>
              </ModalLink>
            )}
            <Link path="/" type="faucet">
              <Button
                className="normal-case"
                label={t('header.links.faucet')}
                size="base"
                variant="text"
              >
                {t('header.links.faucet')}
              </Button>
            </Link>
            <Link
              path="/release-notes/w3s-2024"
              title={t('header.links.releaseNotes')}
              type="internal"
            >
              <Button
                className="normal-case"
                label={t('header.links.releaseNotes')}
                size="base"
                variant="text"
              >
                {t('header.links.releaseNotes')}
              </Button>
            </Link>
            <Link
              path="https://support.usdc.circle.com/hc/en-us/p/contactus"
              title={t('header.links.help')}
              type="external"
            >
              <Button
                className="normal-case"
                label={t('header.links.help')}
                size="base"
                variant="text"
              >
                {t('header.links.help')}
              </Button>
            </Link>

            <Link className="mt-2" path="/" type="console">
              <Button
                className="w-full normal-case"
                size="base"
                variant="primary"
              >
                {t('header.links.console')}
              </Button>
            </Link>
          </div>
        )}
      </Sidenav>
    </div>
  )
}
