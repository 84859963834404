import { useCallback, useEffect, useMemo, useState } from 'react'

import { useFormContext } from '@circlefin/form'
import {
  CWAccordionPanel,
  CWAccordionPanelContent,
  CWAccordionPanelHeader,
} from '@features/common.components/ComponentsWeb/Accordion'
import { CWIcon } from '@features/common.components/ComponentsWeb/Icon'
import { uuid } from '@shared/utils'

import { FormInputField } from '../../FormInputField'
import { getInputLabel } from '../../getInputLabel/getInputLabel'

import { AddNewButton } from './AddNewButton/AddNewButton'

import type { FormInputFieldProps } from '../../types'
import type { OnSelect } from './AddNewButton/AddNewButton'
import type { FieldType } from '@shared/openapi/transform/field/types'
import type { Field } from '@shared/openapi/types'

/**
 * Props for the ArrayPrimitiveInputField component.
 * Extends FormInputFieldProps with a Field.ArrayField schema.
 */
export type ArrayPrimitiveInputFieldProps =
  FormInputFieldProps<Field.ArrayField>

/**
 * A field with an added `id` attribute to uniquely identify it for react render keying.
 */
export type FieldWithId = FieldType & { id: string }

/**
 * A form input field for defining array values. Supports boolean, string, number, integer, object and oneOf.
 * @param props - The component props.
 * @returns The rendered ArrayPrimitiveInputField component.
 */
export const ArrayPrimitiveInputField: React.FC<
  ArrayPrimitiveInputFieldProps
> = ({ schema, name, prefix }) => {
  const controlName = prefix ? `${prefix}.${name}` : name
  const { setFocus, getValues, setValue } = useFormContext()
  const label = useMemo(() => getInputLabel({ schema, name }), [schema, name])

  const [fields, setFields] = useState<FieldWithId[]>([])

  const onSelect = useCallback<OnSelect>(
    (schema, defaultValue) => {
      setFields((prev) => [...prev, { ...schema, id: uuid() }])

      // getValues() returns an `any` value, but we only use it if it passes the array check
      const values = Array.isArray(getValues(controlName))
        ? (getValues(controlName) as unknown[])
        : []
      setValue(controlName, [...values, defaultValue])
    },
    [controlName, getValues, setValue],
  )

  useEffect(() => {
    let id: NodeJS.Timeout | undefined
    if (fields.length > 0) {
      id = setTimeout(
        // this needs to be executed after dom has updated
        () => setFocus(getControlNameToFocus(fields, controlName)),
        0,
      )
    }
    return () => clearTimeout(id)
  }, [fields, controlName, setFocus])

  const onRemove = useCallback(
    (index: number) => () => {
      setFields((prev) => prev.filter((_, i) => i !== index))

      // getValues() returns an `any` value, but we only use it if it passes the array check
      const values = Array.isArray(getValues(controlName))
        ? (getValues(controlName) as unknown[])
        : []
      setValue(
        controlName,
        values.filter((_, i) => i !== index),
      )
    },
    [controlName, getValues, setValue],
  )

  return (
    <div className="w-full" data-testid="array-object-field">
      <label className="text-sm font-circular-bold">{label}</label>
      {fields.map((field, index) => {
        return (
          <CWAccordionPanel
            // we want only want to force rerender the field if the type is not `oneOf` or `anyOf`
            // if it is `oneOf` or `anyOf`, we want to keep the same key for the field so that the tab state is preserved, and the inner field rerenders on its own
            // all other types need to be rerendered to update their inner values which is why we rerender when the index changes
            key={`${field.id}${
              ['oneOf', 'anyOf'].includes(fields[index].type) ? '' : index
            }`}
            className="my-1 [&_.cb-card]:border-neutral [&_.cb-card]:rounded-sm [&_.accordion-panel-header-icon]:pl-4"
            open
          >
            <CWAccordionPanelHeader>
              <div className="text-left capitalize">{schema.items.type}</div>
              <CWIcon
                aria-label="Close"
                className="float-right"
                name="TrashOutline"
                onClick={onRemove(index)}
                size={24}
              />
            </CWAccordionPanelHeader>
            <CWAccordionPanelContent>
              <FormInputField
                // we don't want to display a boxed in field since it's already boxed in by the accordion
                displayObjectBox={false}
                displayOneOfBox={false}
                name={String(index)}
                prefix={controlName}
                schema={fields[index]}
              />
            </CWAccordionPanelContent>
          </CWAccordionPanel>
        )
      })}
      <AddNewButton onSelect={onSelect} schema={schema} />
    </div>
  )
}

/**
 * Get the control name to focus on when adding a new field.
 * @param schemas - The array of schemas.
 * @param prefix - The prefix of field.
 * @returns The control name to focus on.
 */
export function getControlNameToFocus(
  schemas: FieldType[],
  prefix = '',
): string {
  if (schemas.length === 0) return prefix

  const indexOfLast = schemas.length - 1
  const lastSchema = schemas[indexOfLast]
  let controlPath = prefix ? `${prefix}.${indexOfLast}` : String(indexOfLast)
  if (lastSchema.type === 'object') {
    controlPath += '.' + Object.keys(lastSchema.properties)[0]
  }
  return controlPath
}
