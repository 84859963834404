import type { ModalHistoryOptions } from '@circlefin/modal-router'

/**
 * Helper function to define modal route config.
 */
export const screenConfig = (
  options: ModalHistoryOptions = {},
): ModalHistoryOptions => ({
  size: 'md',
  ...options,
})
