'use client'

import type { MouseEventHandler } from 'react'
import { Fragment, useCallback, useMemo } from 'react'

import { AccordionPanel } from '@circlefin/components'
import classNames from 'classnames'

import { OperationItem } from '../../../OperationItem/OperationItem'
import { useTagContext } from '../../TagProvider/TagProvider'

import type { CommonProps } from '../../../types'
import type { OperationSummary } from '@features/playground.graphql'

/**
 * Props for the TagItem component.
 */
interface TagItemProps extends CommonProps {
  /** The key of the project this tag belongs to. */
  projectKey: string
  /** The name of the tag. */
  tag: string
  /** The operations associated with this tag. */
  operations: OperationSummary[]
}

/**
 * TagItem component displays a collapsible section for a specific tag,
 * containing the operations associated with that tag.
 * @param props - The component props.
 * @returns A React component rendering a collapsible tag section.
 */
export const TagItem: React.FC<TagItemProps> = ({
  projectKey,
  tag,
  query,
  operations,
  selectedOperation,
  link,
  onOperationSelection,
}) => {
  const { activeTag, setActiveTag } = useTagContext()

  /**
   * Determines if this tag contains the currently selected operation.
   */
  const containsSelectedOperation = useMemo(
    () =>
      selectedOperation &&
      operations.some((operation) => operation.slug === selectedOperation.slug),
    [selectedOperation, operations],
  )

  /**
   * Determines whether the accordion should be open.
   *
   * The accordion is considered open if any of the following conditions are true:
   * 1. This tag is currently active (selected by the user)
   * 2. There's an active search query (to show all matching results)
   * 3. The currently selected operation belongs to this tag.
   *
   * Note: If there are no operations for this tag, it will always be closed.
   */
  const isOpen = useMemo(() => {
    if (operations.length === 0) return false

    const isActiveTag =
      activeTag?.project === projectKey && activeTag.tag === tag
    const hasSearchQuery = Boolean(query)

    return isActiveTag || hasSearchQuery || containsSelectedOperation
  }, [activeTag, projectKey, tag, query, containsSelectedOperation, operations])

  /**
   * Handles the toggle action when the user clicks on the tag header.
   * Prevents closing the tag if it contains the selected operation.
   */
  const handleToggle: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      // We do not want to allow components-web to handle the click functionality
      event.stopPropagation()

      const isActiveTag =
        activeTag?.project === projectKey && activeTag.tag === tag

      // Prevent closing the tag if it contains the selected operation
      if (containsSelectedOperation && isActiveTag) {
        return
      }

      setActiveTag(isActiveTag ? null : { project: projectKey, tag })
    },
    [activeTag, setActiveTag, projectKey, tag, containsSelectedOperation],
  )

  if (operations.length === 0) {
    return null
  }

  const panelClassName = classNames(
    '[&_.accordion-panel-header-icon]:hidden [&_.accordion-panel-header]:px-1.5 [&_.accordion-panel-header]:py-2 [&_.accordion-panel-content]:px-0 [&_.accordion-panel-header]:border [&_.accordion-panel-header]:rounded-sm',
    {
      '[&_.accordion-panel-header]:border-gradient-selected': isOpen,
      '[&_.accordion-panel-header]:border-white': !isOpen,
    },
  )

  const headerClassName = classNames(
    'block w-full font-circular-medium text-left text-sm',
    {
      'text-neutral-strong font-circular-bold': isOpen,
      'text-neutral': !isOpen,
    },
  )

  return (
    <div className="w-full" data-testid="tag-item">
      <AccordionPanel
        key={isOpen ? 'open' : 'closed'}
        className={panelClassName}
        onCard={false}
        open={isOpen}
      >
        <AccordionPanel.Header onClickCapture={handleToggle}>
          <div className={headerClassName}>{tag}</div>
        </AccordionPanel.Header>
        <AccordionPanel.Content>
          <div className="w-full py-0.5 space-y-0.5">
            {operations.map((operation) => (
              <Fragment key={operation.slug}>
                <OperationItem
                  active={operation.slug === selectedOperation?.slug}
                  handleClick={onOperationSelection}
                  link={link}
                  operation={operation}
                />
              </Fragment>
            ))}
          </div>
        </AccordionPanel.Content>
      </AccordionPanel>
    </div>
  )
}
