import type { NavigationItem } from '../types'

/**
 * Check if the item (or it's children when available) is active.
 */
export const isItemActive = (item: NavigationItem, pathname: string) => {
  if (item.children)
    return (
      item.url === pathname ||
      item.children.some(
        (childItem) => (childItem as NavigationItem).url === pathname,
      )
    )
  return item.url === pathname
}
