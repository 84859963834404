'use client'

import type { ReactNode } from 'react'

import { ModalHistoryProvider } from '@circlefin/modal-router/History/History'
import { ModalRouter } from '@shared/modals/router'

import type { ModalRegistry } from '@circlefin/modal-router'

export interface ModalProviderProps {
  /**
   * Children of the Provider.
   */
  children: ReactNode
  /**
   * Modal registry that will contain all modals that exist throughout the project.
   */
  registry: ModalRegistry
  /**
   * Factory for all modal routers.
   */
  routers: () => ReactNode
}

export const ModalProvider: React.FC<ModalProviderProps> = ({
  children,
  registry,
  routers,
}) => {
  return (
    <ModalHistoryProvider registry={registry}>
      {children}
      <ModalRouter routers={routers} />
    </ModalHistoryProvider>
  )
}
