import { Suspense, useCallback, useEffect } from 'react'

import { Button, Icon, Input, Modal } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useDebounce } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { SearchDocument } from '@features/algolia.graphql'
import { useLoadableQuery } from '@shared/apollo'

import { Results } from './Results/Results'

import type { SubmitHandler } from '@circlefin/form/useForm'

const schema = y.object().shape({
  search: y.string(),
})

type FormSchema = y.InferType<typeof schema>

export const Search: React.FC = () => {
  const { close } = useModal()

  const [Form, { resetField, handleSubmit: triggerSubmit, setFocus }] =
    useForm<FormSchema>({ schema })

  const [search, searchRef] = useLoadableQuery(SearchDocument)

  const handleSubmit: SubmitHandler<FormSchema> = useCallback(
    (values) => {
      if (values.search) {
        search({ input: { searchKey: values.search } })
      }
    },
    [search],
  )

  useEffect(() => {
    setFocus('search')
  }, [setFocus])

  const handleInputClear = useCallback(() => {
    void resetField('search')
  }, [resetField])

  const handleChange = useDebounce({
    callback: () => {
      void triggerSubmit(handleSubmit, () => null)()
    },
    delay: 1000,
  })

  const handleClose = useCallback(() => {
    close()
  }, [close])

  return (
    <Form className="modal-top" onSubmit={handleSubmit}>
      <Modal.Body className="space-y-4">
        <div className="flex space-x-4">
          <Form.Input
            className="w-full"
            name="search"
            onChange={handleChange}
            autoFocus
          >
            <Input.Inline.Icon
              align="left"
              data-testid="input-icon"
              name="SearchOutline"
            />
            <Input.Inline.IconButton
              align="right"
              data-testid="input-icon-button"
              label="Input"
              name="XSolid"
              onClick={handleInputClear}
            />
          </Form.Input>
          <Button className="normal-case" onClick={handleClose} variant="text">
            Cancel
          </Button>
        </div>
        <div className="space-y-2">
          <Suspense
            fallback={
              <div className="flex items-center justify-center min-h-64">
                <Icon
                  className="animate-spin text-interactive"
                  name="CircleSpinnerSolid"
                  size={22}
                />
              </div>
            }
          >
            {searchRef ? <Results queryRef={searchRef} /> : null}
          </Suspense>
        </div>
      </Modal.Body>
    </Form>
  )
}
