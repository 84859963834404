/**
 * Public Key - response model for Circle /encrypt API.
 */
interface PublicKey {
  /**
   * Key ID for the public key.
   */
  keyId: string
  /**
   * Public key generated by circle API to encrypt any sensitive data.
   */
  publicKey: string
}

/**
 * Utility method that allows encrypting data with Circle publicKey.
 */
export async function encrypt(
  dataToEncrypt: unknown,
  { keyId, publicKey }: PublicKey,
): Promise<{
  keyId: string
  encryptedMessage: string
}> {
  const {
    createMessage,
    encrypt: pgpEncrypt,
    readKey,
  } = await import('openpgp')

  // The public key must be obtained from the Circle account (via API call)
  if (!publicKey || !keyId) {
    throw new Error('Unable to encrypt data')
  }

  /**
   * Decoding public key steps.
   * 1. Decode public key from Base64 format to string.
   * 2. Decode public key string from ASCII armored format.
   * 3. Read/parse public key via OpenPGP.
   */
  const decodedPublicKey = await readKey({
    armoredKey: Buffer.from(publicKey, 'base64').toString('utf-8'),
  })

  // Create card details as JSON.
  const message = await createMessage({ text: JSON.stringify(dataToEncrypt) })

  // Encrypt card JSON using public key (via OpenPGP).
  const ciphertext = await pgpEncrypt({
    message,
    encryptionKeys: decodedPublicKey,
  })

  // Return encoded encrypted card details in Base64 format.
  return {
    encryptedMessage: Buffer.from(ciphertext as string, 'utf-8').toString(
      'base64',
    ),
    keyId,
  }
}
