'use client'

import { useEffect, useState } from 'react'

import { ModalRouterProvider, useModal } from '@circlefin/modal-router'
import { usePathname } from 'next/navigation'

export interface ModalRouterProps {
  /**
   * ModalRouters of app containing <ModalRoutes />.
   */
  routers: () => React.ReactNode
}

/**
 * Modal Routes.
 */
export const ModalRouter: React.FC<ModalRouterProps> = ({ routers }) => {
  const pathname = usePathname()
  const modalRouter = useModal()
  const [currentPathname, setCurrentPathname] = useState(() => pathname)

  useEffect(() => {
    // Skip modal close on keep page route open flag.
    if (
      pathname !== currentPathname &&
      modalRouter.history.route?.options?.keepOpenOnPageRoute !== true
    ) {
      modalRouter.router.clear()
    }
    setCurrentPathname(pathname)
  }, [currentPathname, pathname, modalRouter])

  return (
    <ModalRouterProvider>
      {/* Modal Options */}

      {/* Routers */}
      {routers()}
    </ModalRouterProvider>
  )
}
