import { isFieldType } from '@shared/openapi/guards'
import * as y from 'yup'

import { getInputSchema } from '../getInputSchema/getInputSchema'

import type { OperationParameterInfoFragment } from '@features/playground.graphql'

export const getParameterSchema = (
  parameters: OperationParameterInfoFragment[],
) => {
  const schema = parameters.reduce((result, parameter) => {
    if (!isFieldType(parameter.schema)) return result
    return {
      ...result,
      [parameter.name]: getInputSchema(parameter.schema, parameter.required),
    }
  }, {})

  return Object.keys(schema).length > 0
    ? y.object(schema).required()
    : y.object({})
}
