import type { MutableRefObject } from 'react'
import { createContext, useContext } from 'react'

import type {
  PlaygroundEventEmitterType,
  FormChangeEventPayload,
} from '@features/playground.constants/events'
import type { Operation } from '@features/playground.graphql'
import type Emittery from 'emittery'

/**
 * Represents the state of the API Playground context.
 * This interface defines the structure of the state that is shared across the API Playground components.
 */
export interface PlaygroundState {
  /**
   * A mutable reference to the current form input data.
   * This reference is updated whenever the form fields change.
   */
  input: MutableRefObject<FormChangeEventPayload>

  /**
   * A mutable reference to the current form validation state.
   * This boolean value indicates whether the form is currently valid.
   */
  valid: MutableRefObject<boolean>

  /**
   * The currently selected operation.
   */
  operation: Operation
}

/**
 * Represents the actions available in the API Playground context.
 * This interface defines the methods that can be used to interact with the API Playground state.
 */
export interface PlaygroundActions {
  /**
   * The event emitter for playground events.
   * This emitter is used to broadcast form changes and validation state changes.
   */
  emitter: Emittery<PlaygroundEventEmitterType>

  /**
   * Handles form changes by updating the input state and emitting a change event.
   * @param data - The new form data.
   */
  handleFormChange: (data: FormChangeEventPayload) => void

  /**
   * Handles form validation state changes by updating the valid state and emitting a validation event.
   * @param isValid - The new validation state.
   */
  handleFormValidationStateChange: (isValid: boolean) => void

  /**
   * Handles operation selection changes by resetting the input and valid state.
   * @param operation - The newly selected operation or null if deselected.
   */
  handleOperationSelection: (operation: string | null) => void
}

/**
 * The type of the Playground context, combining state and actions.
 */
export type PlaygroundContextType = [PlaygroundState, PlaygroundActions]

/**
 * Default values for the Playground context.
 * This is used to provide a type-safe default value for the context.
 */
export const defaultContextValue: PlaygroundContextType = [
  {
    input: { current: {} },
    valid: { current: true },
    operation: {} as Operation,
  },
  {
    emitter: {} as Emittery<PlaygroundEventEmitterType>,
    handleFormChange: () => {},
    handleFormValidationStateChange: () => {},
    handleOperationSelection: () => {},
  },
]

/**
 * Context for the API Playground.
 * This context provides the state and actions for the API Playground to all child components.
 */
export const PlaygroundContext =
  createContext<PlaygroundContextType>(defaultContextValue)

/**
 * Hook to access the API Playground context.
 * This hook provides a convenient way for components to consume the API Playground context.
 * @returns A tuple containing the API Playground state and actions.
 * @throws If used outside of a PlaygroundProvider.
 */
export const usePlaygroundContext = (): PlaygroundContextType => {
  const context = useContext(PlaygroundContext)

  if (context === defaultContextValue) {
    throw new Error(
      'usePlaygroundContext must be used within a PlaygroundProvider',
    )
  }

  return context
}
