import { isAnyField, isAnyOfField } from '@shared/openapi/guards'

import { getAnyInputSchema } from './getAnyInputSchema/getAnyInputSchema'
import { getAnyOfInputSchema } from './getAnyOfInputSchema/getAnyOfInputSchema'
import { getArrayInputSchema } from './getArrayInputSchema/getArrayInputSchema'
import { getBooleanInputSchema } from './getBooleanInputSchema/getBooleanInputSchema'
import { getIntegerInputSchema } from './getIntegerInputSchema/getIntegerInputSchema'
import { getNumberInputSchema } from './getNumberInputSchema/getNumberInputSchema'
import { getObjectSchema } from './getObjectSchema/getObjectSchema'
import { getOneOfInputSchema } from './getOneOfInputSchema/getOneOfInputSchema'
import { getStringInputSchema } from './getStringInputSchema/getStringInputSchema'

import type { GetInputSchema } from './types'

export const getInputSchema: GetInputSchema = (schema, required = false) => {
  const isRequired =
    isAnyField(schema) || isAnyOfField(schema) ? required : schema.required

  switch (schema.type) {
    case 'array':
      return getArrayInputSchema(getInputSchema, schema, isRequired)
    case 'boolean':
      return getBooleanInputSchema(isRequired)
    case 'integer':
      return getIntegerInputSchema(schema, isRequired)
    case 'number':
      return getNumberInputSchema(schema, isRequired)
    case 'object':
      return getObjectSchema(getInputSchema, schema, isRequired)
    case 'string':
      return getStringInputSchema(schema, isRequired)
    case 'any':
      return getAnyInputSchema(getInputSchema, schema, isRequired)
    case 'oneOf':
      return getOneOfInputSchema(getInputSchema, schema, isRequired)
    case 'anyOf':
      return getAnyOfInputSchema(getInputSchema, schema, isRequired)
  }
}
