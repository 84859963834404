import { useState, useEffect } from 'react'

export type ScrollDirection = 'UP' | 'DOWN'

/**
 * Minimum scroll distance (in pixels) required to trigger a direction change.
 * Helps prevent small or accidental scrolls from updating the direction.
 */
const scrollDelta = 5

/**
 * Hook to track scroll direction. Tracks only vertical scroll.
 * @returns String value - 'up' | 'down'.
 */
export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>()

  useEffect(() => {
    let lastScrollY = window.scrollY

    const updateScrollDirection = () => {
      const scrollY = window.scrollY
      const direction = scrollY > lastScrollY ? 'DOWN' : 'UP'
      if (
        direction !== scrollDirection &&
        Math.abs(scrollY - lastScrollY) > scrollDelta
      ) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY
    }
    window.addEventListener('scroll', updateScrollDirection)
    return () => {
      window.removeEventListener('scroll', updateScrollDirection)
    }
  }, [scrollDirection])

  return scrollDirection
}
