import { useCallback, useState } from 'react'

import { Button, ComponentGroup } from '@circlefin/components'
import { useController } from '@circlefin/form'

/**
 * Props for the action callback function.
 */
export interface ActionCallbackProps {
  /**
   * Function to set the loading state.
   */
  setLoading: (loading: boolean) => void
  /**
   * Function to set the input value.
   */
  setValue: (value: string) => void
}

/**
 * Type definition for the action callback function.
 */
export type InputFieldAction = (props: ActionCallbackProps) => void

/**
 * Props for the ActionInputField component.
 */
export interface ActionInputFieldProps {
  /**
   * The text to display on the action button.
   */
  actionName: string
  /**
   * The action to perform when the button is clicked.
   */
  action: InputFieldAction
  /**
   * The name of the input field.
   */
  name: string
  /**
   * Optional prefix for the input field name.
   */
  prefix?: string
  /**
   * Child components to render.
   */
  children: React.ReactNode
}

/**
 * A component that combines an input field with an action button.
 */
export const ActionInputField: React.FC<ActionInputFieldProps> = ({
  actionName,
  action,
  name,
  children,
  prefix,
}) => {
  const [loading, setLoading] = useState(false)

  const { field } = useController({
    name: prefix ? `${prefix}.${name}` : name,
  })

  const handleClick = useCallback(() => {
    action({
      setLoading,
      setValue: field.onChange,
    })
  }, [action, field])

  return (
    <div className="w-full relative">
      <ComponentGroup className="[&>*]:static items-start">
        {children}
        <Button
          className="mt-8"
          loading={loading}
          onClick={handleClick}
          variant="primary"
        >
          {actionName}
        </Button>
      </ComponentGroup>
    </div>
  )
}
