import { isFieldType } from '../isFieldType/isFieldType'

import type { Field } from '../../../types'

/**
 * Checks if the given value is a valid `NumberField` object.
 * @param value - The value to be checked.
 * @returns - `true` if the value is a valid `NumberField` object, `false` otherwise.
 */
export const isNumberField = (value?: unknown): value is Field.NumberField => {
  return isFieldType(value) && value.type === 'number'
}
