import { isFieldType } from '@shared/openapi/guards'
import * as y from 'yup'

import { getApiKeySchema } from '../getApiKeySchema/getApiKeySchema'
import { getInputSchema } from '../getInputSchema/getInputSchema'
import { getParameterSchema } from '../getParameterSchema/getParameterSchema'

import type { OperationInfoFragment } from '@features/playground.graphql'

/**
 * Generates a Yup schema for the entire form based on the operation information.
 *
 * This function creates a schema that includes:
 * - A bearer token field (required if the security schema is 'bearer')
 * - Parameters (header, path, query, cookies)
 * - Request body.
 *
 * The parameters object is only required if there are any parameters defined.
 * The request body schema is only included if it's a valid field type.
 */
export const getFormSchema = (operation: OperationInfoFragment): y.Schema => {
  const parameterSchema = y.object({
    header: getParameterSchema(operation.parameters.header),
    path: getParameterSchema(operation.parameters.path),
    query: getParameterSchema(operation.parameters.query),
    cookies: getParameterSchema(operation.parameters.cookies),
  })

  const requestBodySchema = isFieldType(operation.requestBody)
    ? getInputSchema(operation.requestBody, true)
    : y.object({})

  return y
    .object({
      bearer:
        operation.securitySchema === 'bearer' ? getApiKeySchema() : y.string(),
      parameters: Object.values(operation.parameters).some(
        (params) => params.length > 0,
      )
        ? parameterSchema.required()
        : parameterSchema,
      requestBody: requestBodySchema,
    })
    .required()
}
