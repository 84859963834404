'use client'

import {
  createContext,
  useContext,
  useState,
  useEffect,
  type PropsWithChildren,
  useMemo,
  useCallback,
} from 'react'

/**
 * Represents the state of the active tag.
 * It can either be null (no active tag) or an object containing the active project and tag.
 */
export type State = {
  /**
   * The key of the active project.
   */
  project: string
  /**
   * The name of the active tag.
   */
  tag: string
} | null

/**
 * The type of the context value provided by TagProvider.
 */
export interface TagContextType {
  /**
   * The currently active tag configuration.
   */
  activeTag: State
  /**
   * Method that allows to set the currently active tag.
   * @param state - The new state that should be stored.
   * @returns
   */
  setActiveTag: (state: State) => void
}

/**
 * Context for managing the active tag state across the application.
 * The context value is undefined by default and will be set by the TagProvider.
 */
export const TagContext = createContext<TagContextType | undefined>(undefined)

/**
 * Props for the TagProvider component.
 */
interface TagProviderProps extends PropsWithChildren {
  /**
   * Optional initial state for the tag context.
   * If provided, it will set the initial active project and tag.
   */
  initialState?: State
}

/**
 * TagProvider component manages the state of the active tag and project.
 * It provides this state and a setter function to its children via context.
 * The internal state is updated whenever the initialState prop changes.
 * @param props - The component props.
 * @returns A React component that provides tag context to its children.
 */
export const TagProvider: React.FC<TagProviderProps> = ({
  children,
  initialState = null,
}) => {
  const [state, setState] = useState<State>(initialState)

  const setActiveTag = useCallback((state: State | null) => {
    setState(state)
  }, [])

  // Update internal state when initialState prop changes
  useEffect(() => {
    setState(initialState)
  }, [initialState])

  const value = useMemo<TagContextType>(
    () => ({ activeTag: state, setActiveTag }),
    [setActiveTag, state],
  )

  return (
    <TagContext.Provider data-testid="tag-provider" value={value}>
      {children}
    </TagContext.Provider>
  )
}

/**
 * Custom hook to use the tag context.
 * This hook provides access to the current active tag state and a function to update it.
 * @returns A tuple containing the current tag state and a function to update it.
 * @throws Error if used outside of a TagProvider.
 */
export const useTagContext = (): TagContextType => {
  const context = useContext(TagContext)
  if (context === undefined) {
    throw new Error('useTagContext must be used within a TagProvider')
  }
  return context
}
