import React from 'react'

import useTranslation from 'next-translate/useTranslation'

import { FormInputField } from '../FormInputField'
import { Heading } from '../Heading/Heading'

import type { FormInputFieldProps } from '../FormInputField/types'

/**
 * An array of strings representing sections that we currently render in the parameter form.
 */
export const parameterTypes = ['header', 'path', 'query', 'cookies'] as const
type ParamsSections = (typeof parameterTypes)[number]
type ParamsSection = ParamsSections | 'requestBody'

/**
 * Props for the Parameters component.
 */
export interface ParametersProps {
  /**
   * The section of parameters to display (header, path, cookies, or query).
   */
  section: ParamsSection
  /**
   * The array of fields to display in the form.
   */
  parameters: FormInputFieldProps[]
  /**
   * The flag to indicate that item is first one in the list, thus should display additional information.
   */
  showInfo?: boolean
}

/**
 * Renders a section of operation parameters.
 */
export const ParametersComponent: React.FC<ParametersProps> = ({
  section,
  parameters,
  showInfo,
}) => {
  const { t } = useTranslation('playground')

  const prefix = parameterTypes.includes(section as ParamsSections)
    ? `parameters.${section}`
    : section

  return (
    <>
      <Heading showInfo={showInfo}>{t(`parameter.titles.${section}`)}</Heading>
      <div className="space-y-4" data-testid="parameters-section">
        {parameters.map((parameter) => {
          return (
            <FormInputField
              key={parameter.name}
              // we don't want the top level object boxed in
              displayObjectBox={false}
              name={parameter.name}
              prefix={prefix}
              // We already filtered out parameters that do not have a valid schema
              schema={parameter.schema}
            />
          )
        })}
      </div>
    </>
  )
}

export const Parameters = React.memo(ParametersComponent)
