import { isFieldType } from '../isFieldType/isFieldType'

import type { Field } from '../../../types'

/**
 * Checks if the given value is a valid `StringField` object.
 * @param value - The value to be checked.
 * @returns - `true` if the value is a valid `StringField` object, `false` otherwise.
 */
export const isStringField = (value?: unknown): value is Field.StringField => {
  return isFieldType(value) && value.type === 'string'
}
