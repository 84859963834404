import { useMemo } from 'react'

import classNames from 'classnames'

import { InputType } from '../InputType/InputType'
import { getInputLabel } from '../getInputLabel/getInputLabel'

import type { FormInputFieldProps, WithFormInputField } from '../types'
import type { Field } from '@shared/openapi/types'

export type ObjectInputFieldProps = FormInputFieldProps<Field.ObjectField> &
  WithFormInputField

export const ObjectInputField: React.FC<ObjectInputFieldProps> = ({
  schema,
  FormInputField,
  prefix,
  name,
  displayObjectBox = true,
}) => {
  const controlName = useMemo(
    () => [prefix, name].filter(Boolean).join('.'),
    [name, prefix],
  )
  return (
    <div>
      {displayObjectBox && (
        <div className="mb-1 relative">
          <p className="type-body-sm-bold text-neutral">
            {getInputLabel({
              schema,
              name,
            })}
          </p>
          <InputType schema={schema} />
        </div>
      )}

      <div
        className={classNames('w-full space-y-4', {
          'border border-neutral rounded-md p-4': displayObjectBox,
        })}
        data-testid="inputfield-object"
      >
        {Object.keys(schema.properties).map((name) => {
          return (
            <FormInputField
              key={name}
              name={name}
              prefix={controlName}
              schema={schema.properties[name]}
            />
          )
        })}
      </div>
    </div>
  )
}
