import * as y from 'yup'

import type { GetInputSchema } from '../types'
import type { Field } from '@shared/openapi/types'

export const getArrayInputSchema = (
  getInputSchema: GetInputSchema,
  schema: Field.ArrayField,
  required = false,
) => {
  let rule = y.array().of(getInputSchema(schema.items, schema.required))
  if (required) {
    rule = rule.required()
  }
  return rule
}
