'use client'

import type { ChangeEvent, ChangeEventHandler, RefObject } from 'react'
import { useCallback, useRef } from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

import classNames from 'classnames'

import { ExpandCodeButton } from '../CodeModal/ExpandCodeButton/ExpandCodeButton'

import { CopyToClipboard } from './CopyToClipboard/CopyToClipboard'

export interface CodePanelProps {
  /**
   * Specify whether or not a background and border should be applied.
   */
  styled?: boolean
  /**
   * The language to highlight code in.
   * Docs: https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/HEAD/AVAILABLE_LANGUAGES_PRISM.MD.
   */
  language?: string
  /**
   * Code that should be displayed.
   */
  code: object | string
  /**
   * Optional property to show Expand button.
   */
  canExpand?: boolean
  /**
   * Optional property to make panel Editable and listen to the output.
   */
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  /**
   * Optional property to listen onBlur event when code panel is editable.
   */
  onBlur?: ChangeEventHandler<HTMLTextAreaElement>
  /**
   * Optional callback that will pass error when JSON parse fails.
   */
  onError?: (error: string | null) => void
  /**
   * Additional classnames that will be added to the root element.
   */
  className?: string
  /**
   * Optional property to make it not editable.
   */
  disabled?: boolean
  /**
   * Optional property to set form input name when editable.
   */
  name?: string
  /**
   * Placeholder to be shown when panel is Editable.
   */
  placeholder?: string
  /**
   * Optional property to make panel read only when Editable.
   */
  readOnly?: boolean
  /**
   * Optional property to pass to textarea when panel is Editable.
   */
  textareaRef?: RefObject<HTMLTextAreaElement>
}

export const CodePanel: React.FC<CodePanelProps> = ({
  canExpand = true,
  code,
  className,
  disabled,
  onChange,
  onBlur,
  onError,
  name,
  placeholder,
  readOnly,
  styled = true,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const preRef = useRef<HTMLPreElement>(null)
  const onScroll = useCallback(() => {
    if (textareaRef?.current && preRef.current) {
      preRef.current.scrollTop = textareaRef.current.scrollTop
      preRef.current.scrollLeft = textareaRef.current.scrollLeft
    }
  }, [textareaRef])

  const onBlurCB = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (event) => {
      let errorMessage = null
      try {
        onChange?.({
          target: {
            value: JSON.stringify(JSON.parse(event.target.value), null, 2),
          },
        } as ChangeEvent<HTMLTextAreaElement>)
      } catch (e) {
        errorMessage = 'Failed to parse JSON.'
        if (e instanceof SyntaxError && e.message) {
          errorMessage = e.message
        }
      } finally {
        onError?.(errorMessage)
      }

      onBlur?.(event)
    },
    [onBlur, onChange, onError],
  )

  const codeString =
    typeof code === 'object' ? JSON.stringify(code, null, 2) : code

  return (
    <div
      className={classNames(
        'w-full group overflow-hidden relative',
        {
          'bg-code border border-neutral-subtle rounded-sm': styled,
        },
        className,
      )}
    >
      {onChange ? (
        <textarea
          ref={textareaRef}
          className="absolute w-full inset-0 resize-none bg-transparent whitespace-nowrap pr-6 pl-12 pt-4 min-h-12 text-xs font-mono text-transparent caret-black outline-none"
          disabled={disabled}
          name={name}
          onBlur={onBlurCB}
          onChange={onChange}
          onScroll={onScroll}
          placeholder={placeholder}
          readOnly={readOnly}
          value={codeString}
        />
      ) : null}
      <div className="absolute hidden top-2 right-2 group-hover:flex">
        <CopyToClipboard code={code} />
        {canExpand ? (
          <ExpandCodeButton
            code={code}
            disabled={disabled}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            styled={styled}
          />
        ) : null}
      </div>
      <SyntaxHighlighter
        CodeTag={Code}
        PreTag={Pre}
        language="js"
        lineNumberStyle={{ minWidth: '2em' }}
        preRef={preRef}
        showLineNumbers
        wrapLongLines
      >
        {codeString}
      </SyntaxHighlighter>
    </div>
  )
}

const Code: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  style,
  ...props
}) => {
  return <code {...props} role="code" />
}

const Pre: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLPreElement>,
    HTMLPreElement
  > & { preRef: RefObject<HTMLPreElement> }
> = ({ style, preRef, ...props }) => (
  <pre
    ref={preRef}
    className="overflow-x-auto px-6 py-4 min-h-12 text-xs font-circular-light bg-code"
    data-testid="code-panel-pre"
    {...props}
  />
)
