'use client'
import type { FC, HTMLAttributeAnchorTarget, MouseEventHandler } from 'react'

import { Sidenav } from '@circlefin/components'
import Link from 'next/link'

export interface NavItemProps {
  /**
   * Custom styles.
   */
  className?: string
  /**
   * Navigation link url.
   */
  href: string
  /**
   * Navigation link label.
   */
  label: string
  /**
   * If the link is active, default to false.
   */
  active?: boolean
  /**
   * Specifies where to open the linked page.
   */
  target?: HTMLAttributeAnchorTarget
  /**
   * On click event that is run when clicked.
   */
  onClick?: MouseEventHandler<HTMLElement>
}

export const NavItem: FC<NavItemProps> = ({
  className,
  href,
  label,
  active,
  target,
  onClick,
}) => {
  return (
    <div className="h-full">
      <Link href={href} legacyBehavior passHref>
        <Sidenav.Group.Item
          active={active}
          className={className}
          onClick={onClick}
          target={target}
        >
          {label}
        </Sidenav.Group.Item>
      </Link>
    </div>
  )
}
