import * as y from 'yup'

import type { GetInputSchema } from '../types'
import type { Field } from '@shared/openapi/types'

/**
 * Converts an OpenAPI oneOf schema to a Yup schema.
 * @returns A Yup schema based on the oneOf schema.
 */
export const getOneOfInputSchema = (
  getInputSchema: GetInputSchema,
  schema: Field.OneOfField,
  required = false,
) => {
  let rule = y.object().default(null)

  if (required) {
    rule = rule.nullable().required()
  }

  rule = rule.shape(
    schema.fields.reduce((result, field, index) => {
      // we need to add a form field schema for each `oneOf` field to access the correct form validation
      return {
        ...result,
        [`oneOf-${index}`]: getInputSchema(field).nullable() as y.Schema,
      }
    }, {}),
  )

  return rule as unknown as y.Schema
}
