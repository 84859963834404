import { ModalRoute } from '@circlefin/modal-router'
import { routes } from '@features/playground.constants'

import { InteractivePlayground } from './InteractivePlayground/InteractivePlayground'

export { playgroundModalRegistry } from './registry'

export { routes }

/**
 * Playground Modal Router.
 */
export const PlaygroundModalRouter = () => {
  return (
    <ModalRoute
      content={<InteractivePlayground />}
      path={routes.interactivePlayground}
    />
  )
}
