import { useEffect } from 'react'

import { OperationDocument } from '@features/playground.graphql'
import { useLazyQuery } from '@shared/apollo'

import { Playground } from '../Playground/Playground'

import { Header } from './Header/Header'
import { Placeholder } from './Placeholder/Placeholder'
import { Sidebar } from './Sidebar/Sidebar'

import type { OperationSummary } from '@features/playground.graphql'

/**
 * Props for the ApiPlayground component.
 */
export interface FullScreenPlaygroundProps {
  /**
   * The currently selected operation.
   * If undefined, no operation is selected.
   */
  selectedOperation?: OperationSummary
  /**
   * Callback function to handle operation selection.
   * @param operation - The selected operation.
   */
  onOperationSelection: (operation?: OperationSummary) => void
}

/**
 * FullScreenPlayground component provides an interactive interface for exploring and testing API operations.
 * @returns The rendered ApiPlayground component.
 */
export const FullScreenPlayground: React.FC<FullScreenPlaygroundProps> = ({
  selectedOperation,
  onOperationSelection,
}) => {
  // Fetch operation details using GraphQL
  const [fetchOperation, { data, loading }] = useLazyQuery(OperationDocument, {
    onError: () => {},
  })

  useEffect(() => {
    if (selectedOperation) {
      void fetchOperation({
        variables: {
          identifier: selectedOperation.slug,
        },
      })
    }
  }, [fetchOperation, selectedOperation])

  return (
    <div className="w-full h-screen flex items-stretch justify-start">
      <div className="w-64 lg:w-80 flex-grow-0 h-full border-r border-neutral-subtle">
        <Sidebar
          onOperationSelection={onOperationSelection}
          selectedOperation={selectedOperation}
        />
      </div>
      <div className="min-w-144 flex flex-col h-screen flex-1">
        {selectedOperation ? (
          <>
            <Header loading={loading} operation={data?.operation} />
            {data?.operation ? <Playground operation={data.operation} /> : null}
          </>
        ) : (
          <>
            <Header loading={false} />
            <Placeholder />
          </>
        )}
      </div>
    </div>
  )
}
