import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import { Link } from '../Link/Link'

export const Logo = () => {
  const { t } = useTranslation('common')
  return (
    <Link path="/" target="_self" type="internal">
      <Image
        alt={t('header.logo.alt')}
        height="30"
        loading="eager"
        src="/logo.svg"
        width="220"
        priority
      />
    </Link>
  )
}
