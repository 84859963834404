import * as y from 'yup'

import type { Field } from '@shared/openapi/types'

export const getStringInputSchema = (
  schema: Field.StringField,
  required = false,
) => {
  let rule = y.string()
  if (required) rule = rule.required()
  else {
    // Transform empty string to undefined to satisfy validation
    rule = rule.transform((v: typeof y, o: string) =>
      o === '' ? undefined : v,
    )
  }
  switch (schema.format) {
    case 'uuid':
      rule = rule.test('uuid', (value) => {
        if (!value) return true // Allow empty value since yup uuid() rule does not
        return y.string().uuid().isValidSync(value)
      })
      break
    case 'uuid7':
      rule = rule.matches(
        /^[0-9A-F]{8}-[0-9A-F]{4}-[7|5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
        {
          name: 'uuid',
        },
      )
      break
  }
  if (schema.minLength !== undefined) {
    rule = rule.min(schema.minLength, {
      key: 'minLength',
      values: { min: schema.minLength },
    })
  }
  if (schema.maxLength !== undefined) {
    rule = rule.max(schema.maxLength, {
      key: 'maxLength',
      values: { max: schema.maxLength },
    })
  }
  if (schema.enum !== undefined) {
    rule = rule.oneOf(schema.enum)
  }
  return rule
}
