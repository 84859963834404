import { useCallback, useMemo } from 'react'

import { useFormContext } from '@circlefin/form'
import { createFormInput } from '@circlefin/form/Form.Input'

import { InputType } from '../InputType/InputType'
import { getInputLabel } from '../getInputLabel/getInputLabel'

import type { FormInputFieldProps } from '../types'
import type { Field } from '@shared/openapi/types'

export type NumberInputFieldProps = FormInputFieldProps<Field.NumberField>

const Input = createFormInput()

export const NumberInputField: React.FC<NumberInputFieldProps> = ({
  schema,
  name,
  prefix,
}) => {
  const { setValue, trigger, resetField } = useFormContext()

  const fullName = useMemo(
    () => (prefix ? [prefix, name].join('.') : name),
    [name, prefix],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (!Number.isNaN(value)) {
        if (value === '') {
          resetField(fullName)
          return
        }
        setValue(fullName, +value)
        void trigger(fullName)
      }
    },
    [fullName, resetField, setValue, trigger],
  )

  return (
    <div className="w-full relative">
      <Input
        data-testid="inputfield-number"
        label={getInputLabel({
          schema,
          name,
        })}
        name={fullName}
        onChange={handleChange}
        placeholder={schema.example.toString()}
        type="number"
      />

      <InputType schema={schema} />
    </div>
  )
}
