import { InputType } from '../InputType/InputType'

import { ArrayPrimitiveInputField } from './ArrayPrimitiveInputField/ArrayPrimitiveInputField'
import { MultiSelectEnumField } from './MultiSelectEnumField/MultiSelectEnumField'

import type { FormInputFieldProps } from '../types'
import type { Field } from '@shared/openapi/types'

/**
 * Props for the ArrayInputField component.
 */
export type ArrayInputFieldProps = FormInputFieldProps<Field.ArrayField>

/**
 * A component for rendering input fields for array-type schemas.
 * @param props - The component props.
 * @returns A React component for array input.
 */
export const ArrayInputField: React.FC<ArrayInputFieldProps> = ({
  schema,
  name,
  prefix,
}) => {
  // Determine if the array items are of enum type
  const isEnumArray =
    schema.items.type === 'string' && Array.isArray(schema.items.enum)

  return (
    <div className="w-full relative" data-testid="inputfield-array">
      {isEnumArray ? (
        <MultiSelectEnumField name={name} prefix={prefix} schema={schema} />
      ) : (
        <ArrayPrimitiveInputField name={name} prefix={prefix} schema={schema} />
      )}
      <InputType schema={schema} />
    </div>
  )
}
