'use client'

import { useEffect, useMemo } from 'react'

import { Chip } from '@circlefin/components'
import { useForm } from '@circlefin/form'
import { usePlaygroundContext } from '@features/playground.hooks/usePlaygroundContext'
import { usePlaygroundState } from '@features/playground.hooks/usePlaygroundState'
import { getFormSchema } from '@features/playground.validation'
import { isFieldType } from '@shared/openapi/guards'
import useTranslation from 'next-translate/useTranslation'

import { ApiKeyInputField } from './FormInputField/ApiKeyInputField/ApiKeyInputField'
import {
  parameterTypes,
  type ParametersProps,
  Parameters,
} from './Parameters/Parameters'
import { TrackValidationState } from './TrackValidationState/TrackValidationState'

import type { FormChangeEventPayload } from '@features/playground.constants/events'

/**
 * ParameterForm component for rendering and handling API operation parameters.
 */
export const ParameterForm: React.FC = () => {
  const { t } = useTranslation('playground')
  const [, { handleFormChange, handleFormValidationStateChange }] =
    usePlaygroundContext()
  const { operation } = usePlaygroundState()

  /**
   * Generate form schema and memoize it.
   */
  const schema = useMemo(() => getFormSchema(operation), [operation])

  /**
   * Generate parameters for each form section including requestBody;
   * check that sections are not empty and have only valid fields.
   */
  const parameters = useMemo(() => {
    const params = parameterTypes
      .filter((section) => operation.parameters[section].length)
      .map<ParametersProps>((section) => ({
        section,
        parameters: operation.parameters[section].filter((parameter) =>
          isFieldType(parameter.schema),
        ),
      }))
    if (isFieldType(operation.requestBody)) {
      params.push({
        section: 'requestBody',
        parameters: [{ schema: operation.requestBody, name: '', prefix: '' }],
      })
    }
    return params
  }, [operation])

  const noInputNeeded = useMemo(
    () => operation.securitySchema !== 'bearer' && parameters.length === 0,
    [operation, parameters],
  )

  const [Form, { watch, formState }] = useForm({
    schema,
    defaultValues: {},
  })

  const formValues = watch() as FormChangeEventPayload

  useEffect(() => {
    handleFormChange(
      schema.cast(formValues, { assert: false }) as FormChangeEventPayload,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFormChange, JSON.stringify(formValues)])

  /**
   * Initially send form validation state.
   */
  useEffect(() => {
    handleFormValidationStateChange(formState.isValid)
    // We only want to run this on the initial render or when the operation.name changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation.name])

  return (
    <Form
      key={operation.name}
      aria-label="form"
      className="w-full py-6 px-4 space-y-6"
      data-testid="parameter-form"
    >
      <TrackValidationState />
      {operation.securitySchema === 'bearer' ? <ApiKeyInputField /> : null}
      {parameters.map((parameters, index) => (
        <Parameters
          key={parameters.section}
          parameters={parameters.parameters}
          section={parameters.section}
          showInfo={index === 0}
        />
      ))}
      {noInputNeeded ? (
        <Chip className="rounded-sm border-none p-1" variant="default/success">
          {t('parameter.noneNeeded')}
        </Chip>
      ) : null}
    </Form>
  )
}
