'use client'
import type { FC } from 'react'
import { useCallback, useEffect, useState } from 'react'

import { FixedBanner } from '@circlefin/components/lib/FixedBanner'
import { useElementSize } from '@custom-react-hooks/use-element-size'
import { useFeatureFlag } from '@features/flags.hooks/useFeatureFlag'
import { banners as allBanners } from '@shared/content/src/banners'

import { Link } from '../Link/Link'

export const NotificationBanner: FC = () => {
  const migrationEnabled = useFeatureFlag('migrationEnabled')
  const [setRef, size] = useElementSize()
  const [banners, setBanners] = useState(migrationEnabled ? allBanners : [])

  const onDismiss = useCallback(
    (index: number) => () =>
      setBanners((prev) => prev.slice(index + 1).concat(prev.slice(0, index))),
    [],
  )

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--notification-banner-height',
      `${size.height}px`,
    )
  }, [size])

  return (
    <div ref={setRef}>
      {banners.map((banner, index) => (
        <FixedBanner
          key={banner.title || banner.description}
          level="site"
          onDismiss={onDismiss(index)}
          status="info"
          visible
        >
          <FixedBanner.Title>{banner.title}</FixedBanner.Title>
          <FixedBanner.Description>
            <p>{banner.description}</p>
            {banner.link ? (
              <Link path={banner.link.path}>{banner.link.title}</Link>
            ) : null}
          </FixedBanner.Description>
        </FixedBanner>
      ))}
    </div>
  )
}
