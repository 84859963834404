import { ProjectItem } from './ProjectItem/ProjectItem'
import { TagProvider } from './TagProvider/TagProvider'

import type { CommonProps } from '../types'
import type { OperationProject } from '@features/playground.graphql'

/**
 * Props for the ProjectList component.
 */
export interface ProjectListProps extends CommonProps {
  /**
   * Array of projects to be displayed.
   */
  projects: OperationProject[]
}

/**
 * ProjectList component renders a list of ProjectItem components.
 * It wraps the list with a TagProvider to manage the state of active tags.
 * @param props - The component props.
 * @returns A React component rendering the list of projects.
 */
export const ProjectList: React.FC<ProjectListProps> = ({
  projects,
  selectedOperation,
  query,
  link,
  onOperationSelection,
}) => {
  return (
    <TagProvider>
      <div className="w-full divide-y divide-neutral-subtle">
        {projects.map((project) => (
          <ProjectItem
            key={project.key}
            link={link}
            onOperationSelection={onOperationSelection}
            project={project}
            query={query}
            selectedOperation={selectedOperation}
          />
        ))}
      </div>
    </TagProvider>
  )
}
