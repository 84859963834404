/**
 * Capitalize string.
 */
export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Convert first char to lower case.
 */
export const lowercase = (str: string) => {
  return str.charAt(0).toLowerCase() + str.slice(1)
}
