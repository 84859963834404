import {
  isAnyField,
  isAnyOfField,
  isArrayField,
  isBooleanField,
  isIntegerField,
  isNumberField,
  isObjectField,
  isOneOfField,
  isStringField,
} from '@shared/openapi/guards'

import { ArrayInputField } from './ArrayInputField/ArrayInputField'
import { BooleanInputField } from './BooleanInputField/BooleanInputField'
import { EntitySecretCipherTextInputField } from './EntitySecretCipherTextInputField/EntitySecretCipherTextInputField'
import { IdempotencyKeyField } from './IdempotencyKeyField/IdempotencyKeyField'
import { IntegerInputField } from './IntegerInputField/IntegerInputField'
import { NumberInputField } from './NumberInputField/NumberInputField'
import { ObjectInputField } from './ObjectInputField/ObjectInputField'
import { OneOfInputField } from './OneOfInputField/OneOfInputField'
import { StringInputField } from './StringInputField/StringInputField'

import type { FormInputFieldProps } from './types'
import type { AnyOfField } from '@shared/openapi/transform/field/types'

export const FormInputField: React.FC<FormInputFieldProps> = ({
  schema,
  ...config
}) => {
  if (isBooleanField(schema)) {
    return <BooleanInputField schema={schema} {...config} />
  }

  if (isIntegerField(schema)) {
    return <IntegerInputField schema={schema} {...config} />
  }

  if (isNumberField(schema)) {
    return <NumberInputField schema={schema} {...config} />
  }

  if (isObjectField(schema)) {
    return (
      <ObjectInputField
        FormInputField={FormInputField}
        schema={schema}
        {...config}
      />
    )
  }

  if (isStringField(schema)) {
    // https://circlepay.atlassian.net/browse/DEVX-372
    if (config.name === 'idempotencyKey') {
      return <IdempotencyKeyField schema={schema} {...config} />
    }
    if (config.name === 'entitySecretCiphertext') {
      return <EntitySecretCipherTextInputField schema={schema} {...config} />
    }
    return <StringInputField schema={schema} {...config} />
  }

  if (isArrayField(schema)) {
    return <ArrayInputField schema={schema} {...config} />
  }

  if (isOneOfField(schema) || isAnyOfField(schema)) {
    return (
      <OneOfInputField
        FormInputField={FormInputField}
        schema={schema}
        {...config}
      />
    )
  }

  if (isAnyField(schema)) {
    return (
      <OneOfInputField
        FormInputField={FormInputField}
        schema={{
          ...schema,
          ...AnyInputFieldSchema,
        }}
        {...config}
      />
    )
  }
}

const AnyInputFieldSchema: AnyOfField = {
  type: 'anyOf',
  fields: [
    {
      type: 'boolean',
      deprecated: false,
      description: '',
      example: true,
      required: false,
    },
    {
      type: 'number',
      deprecated: false,
      description: '',
      example: 100,
      exclusiveMax: false,
      exclusiveMin: false,
      required: false,
    },
    {
      type: 'string',
      deprecated: false,
      description: '',
      example: '',
      required: false,
    },
    {
      type: 'array',
      deprecated: false,
      description: '',
      items: {
        type: 'anyOf',
        fields: [
          {
            type: 'boolean',
            deprecated: false,
            description: '',
            example: true,
            required: false,
          },
          {
            type: 'number',
            deprecated: false,
            description: '',
            example: 100,
            exclusiveMax: false,
            exclusiveMin: false,
            required: false,
          },
          {
            type: 'string',
            deprecated: false,
            description: '',
            example: '',
            required: false,
          },
        ],
      },
      required: false,
    },
  ],
}
