import * as y from 'yup'

import type { GetInputSchema } from '../types'
import type { Field } from '@shared/openapi/types'

export const getAnyOfInputSchema = (
  getInputSchema: GetInputSchema,
  schema: Field.AnyOfField,
  required = false,
) => {
  let rule = y.mixed().test({
    message: {
      key: 'anyOf',
      values: {
        types: schema.fields.map((field) => field.type).join(', '),
      },
    },
    test: (value) => {
      const rules = schema.fields.map((field) =>
        getInputSchema(field, required),
      )
      return rules.some((rule) => rule.isValidSync(value))
    },
  })
  if (required) rule = rule.required()
  return rule
}
