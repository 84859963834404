import useTranslation from 'next-translate/useTranslation'

import type { FieldsType } from '@shared/openapi/transform/field/oneOf/oneOf'
import type { Field } from '@shared/openapi/types'
import type { Translate } from 'next-translate'

export interface InputTypeProps {
  /** The schema describing the field. */
  schema: Field.FieldType
}

/**
 * Generates a descriptive string for the input type based on the provided schema.
 * @param schema - The schema describing the field.
 * @param t - The translation function for translating input types.
 * @returns A descriptive string of the input type.
 */
export function getInputTypeDescription(schema: Field.FieldType, t: Translate) {
  const basicTypes = ['string', 'integer', 'number', 'boolean', 'object']
  if (basicTypes.includes(schema.type)) {
    return t(`form.input.${schema.type}`)
  }

  if (schema.type === 'array') {
    return getArrayTypeDescription(schema, t)
  }

  if (schema.type === 'oneOf' || schema.type === 'anyOf') {
    return getOneOfTypeDescription(schema.fields, t)
  }

  return null
}

/**
 * Generates a descriptive string for array types based on the provided schema.
 * @param schema - The schema describing the array field.
 * @param t - The translation function for translating array types.
 * @returns A descriptive string of the array type.
 */
function getArrayTypeDescription(
  schema: Field.ArrayField,
  t: Translate,
): string {
  if (schema.items.type === 'oneOf') {
    const itemTypesList = getOneOfTypeDescription(schema.items.fields, t)
    return t('form.input.arrays.oneOf', { types: itemTypesList })
  }

  return t('form.input.arrays.items', { type: schema.items.type })
}

/**
 * Generates a descriptive string for oneOf types.
 * @param fields - Field of the oneOf array filed.
 * @param t - The translation function for translating array types.
 * @returns A list of available types.
 */
export function getOneOfTypeDescription(
  fields: FieldsType,
  t: Translate,
): string {
  return fields.map((type) => getInputTypeDescription(type, t)).join(', ')
}

/**
 * The InputType component displays a descriptive label based on the input schema.
 * @param props - The properties passed to the InputType component.
 * @returns A React element that renders the input type description.
 */
export const InputType: React.FC<InputTypeProps> = ({ schema }) => {
  const { t } = useTranslation('playground')
  const type = getInputTypeDescription(schema, t)

  return type ? (
    <span className="absolute top-0 right-0 text-sm" data-testid="input-type">
      {type}
    </span>
  ) : null
}
