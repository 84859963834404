import type { FC } from 'react'

import {
  CWAccordionPanel,
  CWAccordionPanelContent,
  CWAccordionPanelHeader,
} from '@features/common.components/ComponentsWeb/Accordion'
import {
  CWHeading,
  CWHeadingHeader,
  CWHeadingIntro,
} from '@features/common.components/ComponentsWeb/Heading'
import useTranslation from 'next-translate/useTranslation'

import { Parameter } from '../Parameter/Parameter'

import type { ParameterProps } from '../Parameter/Parameter'

export interface ResponseProps {
  /**
   * The HTTP status code of the response.
   */
  statusCode?: string | null

  /**
   * The title or brief description of the response.
   */
  title?: string | null

  /**
   * The body of the response.
   */
  body?: {
    /**
     * The type of the response body.
     */
    type?: string | null

    /**
     * An array of parameters describing the structure of the response body.
     */
    parameters: ParameterProps[]
  } | null

  /**
   * The headers of the response.
   */
  headers?: {
    /**
     * The type of the headers.
     */
    type: string

    /**
     * An array of parameters describing the structure of the response headers.
     */
    parameters: ParameterProps[]
  } | null
}

export const Response: FC<ResponseProps> = ({
  statusCode,
  title,
  body,
  headers,
}) => {
  const { t } = useTranslation('playground')

  return (
    <CWAccordionPanel>
      <CWAccordionPanelHeader>
        <CWHeading>
          <CWHeadingHeader>{statusCode}</CWHeadingHeader>
          <CWHeadingIntro>{title}</CWHeadingIntro>
        </CWHeading>
      </CWAccordionPanelHeader>
      <CWAccordionPanelContent>
        {body?.parameters && body.parameters.length > 0 ? (
          <div>
            <div className="type-label-md text-neutral pt-4 pb-2">
              {t`response.bodyTitle`}
            </div>
            <div className="border border-neutral-subtle rounded-lg">
              <div className="border-b border-neutral-subtle pt-3 pl-4 pb-2">
                {body.type}
              </div>
              <div className="pt-3 px-4 pb-2">
                {body.parameters.map((parameter) => (
                  <Parameter key={parameter.name} {...parameter} />
                ))}
              </div>
            </div>
          </div>
        ) : null}
        {headers && headers.parameters.length > 0 ? (
          <div>
            <div className="type-label-md text-neutral pt-4 pb-2">{t`response.headersTitle`}</div>
            <div className="border border-neutral-subtle rounded-lg">
              <div className="border-b border-neutral-subtle pt-3 pl-4 pb-2">
                {headers.type}
              </div>
              <div className="px-4 py-2">
                {headers.parameters.map((parameter) => (
                  <Parameter key={parameter.name} {...parameter} />
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </CWAccordionPanelContent>
    </CWAccordionPanel>
  )
}
