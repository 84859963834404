import React, { useEffect } from 'react'

import { useFormState } from '@circlefin/form'
import { PLAYGROUND_FORM_VALIDATION_STATE_EVENT } from '@features/playground.constants/events'
import { usePlaygroundContext } from '@features/playground.hooks/usePlaygroundContext'

/**
 * TrackValidationState is a component that tracks the form validation state
 * and emits an event when it changes.
 *
 * This component doesn't render anything visible, it's used for side effects only.
 */
export const TrackValidationStateComponent: React.FC = () => {
  const { isValid } = useFormState()
  const [, { emitter }] = usePlaygroundContext()

  /**
   * The number of listeners for the validation state event.
   * We need this to ensure that the validation event is always emitted after that last listener is added.
   * If we do not do this, the emitted event will not trigger the subscription callback in `usePlaygroundState`.
   */
  const listenerCount = emitter.listenerCount(
    PLAYGROUND_FORM_VALIDATION_STATE_EVENT,
  )

  useEffect(() => {
    // Emit the validation state change event
    void emitter.emit(PLAYGROUND_FORM_VALIDATION_STATE_EVENT, isValid)
  }, [emitter, isValid, listenerCount])

  return null
}

export const TrackValidationState = React.memo(TrackValidationStateComponent)
