'use client'

import { useMemo, type FC, useCallback, useState } from 'react'

import { Dropdown } from '@circlefin/components'
import {
  CWHeading,
  CWHeadingHeader,
  CWHeadingIntro,
} from '@features/common.components/ComponentsWeb/Heading'
import useTranslation from 'next-translate/useTranslation'

import { CodePanel } from '../CodePanel/CodePanel'

import type { DropdownItem } from '@circlefin/components/lib/Dropdown'
import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { TransformedResponse } from '@features/playground.graphql'

export interface ResponseSelectionProps {
  /**
   * Items that can be switched between.
   */
  items: TransformedResponse[]
}

export const ResponseSelection: FC<ResponseSelectionProps> = ({ items }) => {
  const { t } = useTranslation('playground')

  const mappedItems: SelectListItem<string>[] = useMemo(() => {
    return items
      .filter((item) => item.title != null)
      .map((item) => {
        return {
          value: item.statusCode,
          label: item.statusCode,
        }
      })
  }, [items])

  const [selectedResponse, setSelectedResponse] = useState<TransformedResponse>(
    items[0],
  )

  const handleChange = useCallback(
    (change: DropdownItem<string>) => {
      const itemToSet = items.find((item) => {
        return item.statusCode === change.value
      })
      if (itemToSet) {
        setSelectedResponse(itemToSet)
      }
    },
    [items],
  )

  return (
    <div className="flex items-end flex-col space-y-4">
      <div className="w-full flex justify-between items-center">
        <CWHeading variant="title/sm">
          <CWHeadingHeader>{t`response.title`}</CWHeadingHeader>
          <CWHeadingIntro>{t`response.subtitle`}</CWHeadingIntro>
        </CWHeading>
        <Dropdown
          className="w-1/2 text-sm max-w-full"
          items={mappedItems}
          onChange={handleChange}
          value={selectedResponse.statusCode}
        >
          <Dropdown.Options items={mappedItems} truncate />
        </Dropdown>
      </div>
      <CodePanel
        className="[&_pre]:max-h-58"
        code={selectedResponse.codeSnippet as object}
      />
    </div>
  )
}
