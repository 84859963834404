import { useCallback, useMemo } from 'react'

import { TabSwitch } from '@circlefin/components/lib/TabSwitch'
import { useFormContext } from '@circlefin/form'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { getInputLabel } from '../getInputLabel/getInputLabel'

import type { FormInputFieldProps, WithFormInputField } from '../types'
import type { Field } from '@shared/openapi/types'

/**
 * Props for the OneOfInputField component.
 */
export type OneOfInputFieldProps = FormInputFieldProps<
  Field.OneOfField | Field.AnyOfField
> &
  WithFormInputField

/**
 * A component for rendering input fields for one of type schemas.
 * @param props - The component props.
 * @returns A React component for one of field input.
 */
export const OneOfInputField: React.FC<OneOfInputFieldProps> = ({
  schema,
  name,
  prefix,
  FormInputField,
  displayOneOfBox = true,
}) => {
  const { t } = useTranslation('playground')

  const { setValue } = useFormContext()

  const handleChangeTabs = useCallback(() => {
    const fieldName = prefix ? [prefix, name].join('.') : name
    setValue(fieldName, undefined)
  }, [setValue, name, prefix])

  /**
   * We want to get the tab ids for the different fields.
   * If the field has a title return that.
   * If a field is the only field of its type return the type.
   * If a field is one of multiple fields with its type return the type with a type-specific index - eg `object 1` and `object 2`.
   */
  const tabIds = useMemo(() => {
    // map of field type => number of fields that have that type
    const fieldTypesMap = new Map<string, number>()

    // build first version of tabIds and build the type map
    const tabIds = schema.fields.map((field) => {
      const typeCount = fieldTypesMap.get(field.type)

      if (typeCount === undefined) {
        fieldTypesMap.set(field.type, 1)
        return `${field.type} ${1}`
      }

      fieldTypesMap.set(field.type, typeCount + 1)
      return `${field.type} ${typeCount + 1}`
    })

    // final version of tabIds
    return schema.fields.map((field, index) => {
      if ('title' in field && field.title !== undefined) return field.title

      const typeCount = fieldTypesMap.get(field.type)

      if (typeCount === 1) {
        return field.type
      }

      return tabIds[index]
    })
  }, [schema.fields])

  const label = useMemo(() => getInputLabel({ schema, name }), [name, schema])

  return (
    <div className="w-full">
      {displayOneOfBox && name && (
        <label className="text-neutral inline-block type-body-sm-bold mb-1">
          {label}
        </label>
      )}
      <div
        className={classNames('w-full space-y-4', {
          'border border-neutral rounded-md p-4': displayOneOfBox,
        })}
        data-testid="inputfield-oneof"
      >
        <TabSwitch onChange={handleChangeTabs} selected={tabIds[0]}>
          <TabSwitch.Tabs variant="underlined-black">
            {displayOneOfBox && (
              <div className="flex grow font-circular-medium mr-4 items-center px-4">
                <span>
                  {schema.type === 'oneOf' ? t`form.oneOf` : t`form.anyOf`}
                </span>
              </div>
            )}
            {tabIds.map((tabId) => (
              <TabSwitch.Tab key={tabId} id={tabId}>
                {tabId}
              </TabSwitch.Tab>
            ))}
          </TabSwitch.Tabs>
          <div>
            {schema.fields.map((field, index) => (
              <TabSwitch.Content key={tabIds[index]} id={tabIds[index]}>
                <FormInputField
                  // we don't want the oneOf fields boxed in if they happen to be objects
                  displayObjectBox={false}
                  name={`${name}.oneOf-${index}`}
                  prefix={prefix}
                  schema={field}
                />
              </TabSwitch.Content>
            ))}
          </div>
        </TabSwitch>
      </div>
    </div>
  )
}
