'use client'

import type React from 'react'
import { useCallback } from 'react'

import { FormProvider as CircleFinFormProvider } from '@circlefin/form/Form.Context'
import useTranslation from 'next-translate/useTranslation'

import type { ValidationError } from '@circlefin/form/Form.Context'

export interface FormProviderProps {
  /**
   * Child Nodes.
   */
  children?: React.ReactNode
}

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const { t } = useTranslation('common')

  const translate = useCallback(({ key, values }: ValidationError) => {
    return t(`forms.validation.${key}`, values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CircleFinFormProvider translate={translate}>
      {children}
    </CircleFinFormProvider>
  )
}
