import { routes } from '@features/common.constants'
import { screenConfig } from '@shared/modals'

import type { ModalRegistry } from '@circlefin/modal-router'

/**
 * Common modal routes registry.
 */
export const commonModalRegistry: ModalRegistry = {
  [routes.feedback]: screenConfig({
    disableBack: true,
    disableClose: false,
    size: 'sm',
  }),
  [routes.featureRequest]: screenConfig({
    disableBack: true,
    disableClose: false,
    size: 'sm',
  }),
  [routes.success]: screenConfig({
    disableBack: true,
    disableClose: false,
    size: 'sm',
  }),
}
