'use client'

import { useMemo } from 'react'

import { TagItem } from './TagItem/TagItem'

import type { CommonProps } from '../../types'
import type { OperationProject } from '@features/playground.graphql'

/**
 * Props for the ProjectItem component.
 */
interface ProjectItemProps extends CommonProps {
  /**
   * The project data to be displayed.
   */
  project: OperationProject
}

/**
 * ProjectItem component displays a single project with its associated tags and operations.
 * It filters operations based on the search query and renders TagItems for each tag with matching operations.
 * Returns null if no operations match the search criteria.
 * @param props - The component props.
 * @returns A React component rendering a single project, or null if no operations match the search.
 */
export const ProjectItem: React.FC<ProjectItemProps> = ({
  project,
  selectedOperation,
  query,
  onOperationSelection,
  link,
}) => {
  const filteredOperations = useMemo(() => {
    return project.operations.filter((operation) =>
      query
        ? operation.method.toLowerCase().includes(query.toLowerCase()) ||
          operation.summary.toLowerCase().includes(query.toLowerCase())
        : true,
    )
  }, [project.operations, query])

  if (filteredOperations.length === 0) {
    return null
  }

  return (
    <div className="w-full py-6 first:pt-0" data-testid="project-item">
      <span className="block text-neutral text-xs font-circular-bold uppercase pl-2 pb-2">
        {project.name}
      </span>
      {project.tags.map((tag) => {
        const tagOperations = filteredOperations.filter((op) =>
          op.tags.includes(tag),
        )
        if (tagOperations.length === 0) {
          return null
        }
        return (
          <TagItem
            key={tag}
            link={link}
            onOperationSelection={onOperationSelection}
            operations={tagOperations}
            projectKey={project.key}
            query={query}
            selectedOperation={selectedOperation}
            tag={tag}
          />
        )
      })}
    </div>
  )
}
