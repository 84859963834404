import { useEffect, useState, useCallback } from 'react'

import { GLOBAL_PLAYGROUND_PROJECT_SETTINGS_CHANGED } from '@features/playground.constants/events'

import { useGlobalPlaygroundContext } from './../useGlobalPlaygroundContext'

import type {
  GlobalProjectSettings,
  GlobalProjectSettingsChangedPayload,
} from '@features/playground.constants/events'

/**
 * A custom hook that provides access to the current project settings of the Global Playground
 * and a method to update these settings.
 * It syncs with the GlobalPlaygroundContext and listens for project settings change events.
 * @returns An object containing the current project settings and a setter function.
 */
export const useGlobalPlaygroundProjectSettings = (): [
  GlobalProjectSettings,
  (key: string, settings: Record<string, string | number>) => void,
] => {
  const [{ project }, { emitter, handleProjectSettingsChange }] =
    useGlobalPlaygroundContext()
  const [settings, setSettings] = useState<GlobalProjectSettings>(
    project.current,
  )

  const handleSettingsChange = useCallback(
    (data: GlobalProjectSettingsChangedPayload) => {
      setSettings(data.settings)
    },
    [setSettings],
  )

  useEffect(() => {
    emitter.on(GLOBAL_PLAYGROUND_PROJECT_SETTINGS_CHANGED, handleSettingsChange)

    /**
     * Cleanup function to unsubscribe from events.
     */
    return () => {
      emitter.off(
        GLOBAL_PLAYGROUND_PROJECT_SETTINGS_CHANGED,
        handleSettingsChange,
      )
    }
  }, [emitter, handleSettingsChange])

  const updateProjectSettings = useCallback(
    (key: string, newSettings: Record<string, string | number>) => {
      handleProjectSettingsChange(key, newSettings)
    },
    [handleProjectSettingsChange],
  )

  return [settings, updateProjectSettings]
}
