import { ModalRoute } from '@circlefin/modal-router'
import { routes } from '@features/algolia.constants'

import { Search } from './Search/Search'

export { algoliaModalRegistry } from './registry'

export { routes }

/**
 * Common Modal Router.
 */
export const AlgoliaModalRouter = () => {
  return (
    <>
      <ModalRoute content={<Search />} path={routes.search} />
    </>
  )
}
