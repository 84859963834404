import type React from 'react'
import type { MouseEvent } from 'react'
import { useCallback } from 'react'

import { useController } from '@circlefin/form'
import { createFormSelectCard } from '@circlefin/form/Form.SelectCard'

import { getInputLabel } from '../../../getInputLabel/getInputLabel'

import type { FormInputFieldProps } from '../../../types'
import type { Field } from '@shared/openapi/types'

const SelectCard = createFormSelectCard()

export type StringEnumDualProps = FormInputFieldProps<Field.StringField>

/**
 * Allows selection between two enum values for a string field using radio cards.
 */
export const StringEnumDual: React.FC<StringEnumDualProps> = ({
  name,
  schema,
  prefix,
}) => {
  const fieldName = [prefix, name].filter(Boolean).join('.')
  const label = getInputLabel({ schema, name })
  const options = schema.enum?.slice(0, 2) as string[]

  const { field } = useController({
    name: fieldName,
  })

  const handleClick = useCallback(
    (value: string) => (event: MouseEvent) => {
      if (field.value === value) {
        field.onChange(undefined)
        event.stopPropagation()
      }
    },
    [field],
  )

  return (
    <div className="w-full">
      <label className="text-neutral inline-block type-body-sm-bold mb-1">
        {label}
      </label>
      <div className="w-full flex items-center justify-center gap-x-4">
        {options.map((value) => (
          <SelectCard
            key={value}
            alignCheck="center"
            className="w-full font-circular-bold"
            label={value}
            name={fieldName}
            onClickCapture={handleClick(value)}
            value={value}
            variant="primary/base"
          >
            {value}
          </SelectCard>
        ))}
      </div>
    </div>
  )
}
