import { useEffect, useMemo, useState } from 'react'

import {
  PLAYGROUND_FORM_CHANGE_EVENT,
  PLAYGROUND_FORM_VALIDATION_STATE_EVENT,
} from '@features/playground.constants/events'

import { usePlaygroundContext } from '../usePlaygroundContext/usePlaygroundContext'

import type { FormChangeEventPayload } from '@features/playground.constants/events'

/**
 * A custom hook that provides access to the current state of the Playground.
 * It syncs with the PlaygroundContext and listens for form change and validation events.
 * @returns An object containing the current input, validation state, operation, and project key.
 */
export const usePlaygroundState = () => {
  const [state, { emitter }] = usePlaygroundContext()
  const [input, setInput] = useState<FormChangeEventPayload>(
    state.input.current,
  )
  const [valid, setValid] = useState<boolean>(state.valid.current)

  useEffect(() => {
    emitter.on(PLAYGROUND_FORM_VALIDATION_STATE_EVENT, setValid)
    emitter.on(PLAYGROUND_FORM_CHANGE_EVENT, setInput)

    /**
     * Cleanup function to unsubscribe from events.
     */
    return () => {
      emitter.off(PLAYGROUND_FORM_VALIDATION_STATE_EVENT, setValid)
      emitter.off(PLAYGROUND_FORM_CHANGE_EVENT, setInput)
    }
  }, [emitter])

  // Memoize the return value to prevent unnecessary re-renders
  return useMemo(
    () => ({
      valid,
      input,
      operation: state.operation,
    }),
    [state.operation, valid, input],
  )
}
